import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { StockingLocation } from '../models/stockinglocation/stockinglocation.model';
import { Observable } from 'rxjs';
import { StockingLocationConstant } from 'src/app/core/constants/stocking-location.constant';
import { StockingTypeValue } from '../models/stockinglocation/stockingTypeValue';

@Injectable({
    providedIn: 'root'
})
export class StockingLocationsService {

    constructor(private httpUtilityService: HttpUtilityService) {
    }

    getStockingLocations(): Observable<StockingLocation[]> {
        return this.httpUtilityService.getAll(StockingLocationConstant.stockingLocationsApi);
    }

    getStockingLocationCodes(assetName: string): Observable<string[]> {
        return this.httpUtilityService.get(StockingLocationConstant.stockingLocationsApi + `code?assetName=${assetName}`);
    }

    upsertStockingLocation(stockingLocations: StockingLocation[]) {
        return this.httpUtilityService.postByAll(StockingLocationConstant.stockingLocationsApi + StockingLocationConstant.upsert, stockingLocations);
    }

    addStockingLocation(stockingLocation: StockingLocation){
        return this.httpUtilityService.post(StockingLocationConstant.stockingLocationsApi, stockingLocation);
    }

    updateStockingLocation(stockingLocation: StockingLocation){
        return this.httpUtilityService.put(StockingLocationConstant.stockingLocationsApi, stockingLocation);
    }

    getStockingTypeValues(): Observable<StockingTypeValue[]> {
        return this.httpUtilityService.get(StockingLocationConstant.stockingLocationsApi + 'stocking-type-values');
    }
}