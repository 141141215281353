export const AddNewItemConstant = {
    createAddNewActionItem: 'api/RequestLine/CreateAddNewItemAction',
    cloneActionItem: 'api/RequestLine/CloneItemAction',
    updateQACodes: 'api/RequestLine/QACodes/',
    successMessage: 'Created request line successfully.',
    updateSuccessMessage: 'Updated request line successfully.',
    errorMessage: 'Please review the highlighted fields.',
    getAddNewItemAction: 'api/RequestLine/GetAddNewItemAction',
    updateAddNewActionItem: 'api/RequestLine/UpdateAddNewItemAction',
    UpdateNewItemOnConsultAction: 'api/RequestLine/consult',
    importAddNewItemAction: 'api/RequestLine/ImportAddNewItemAction',
    bulkActionError: 'Items belonging to both Draft and Rejected status have been selected. Kindly select items of either Draft or Rejected status to perform bulk action',
    stockingLocationForNoMaxValidation: '140061010A',
    assetForNoMaxValidation: 'WA Oil',
    excelvalidations: {
        noRows: 'No rows were found in the Excel spreadsheet',
        invalidDescription: 'Invalid Vendor Description',
        materialTypeValidation: 'Invalid MaterialType',
        umValidation: 'Invalid UM',
        materialGroupCodeValidation: 'Invalid Material Group Code',
        segmentValidation: 'Invalid Segment',
        bu_segmentValidation: 'Segment column is only applicable for GOMBU',
        criticalValidation: 'Critical Part value Yes is not applicable for GOMBU',
        materialStatusValidation: 'Plant Specific Material Status column is only applicable for GOMBU',
        storageConditionValidation: 'Storage Condition column is only applicable for GOMBU',
        hazardousValidation: 'Hazardous value Yes is not applicable for MCBU, JVBU and RBU',
        mrpValidation: 'Invalid MRP',
        unitPriceValidation: 'Invalid Estimated UnitPrice',
        leadTimeValidation: 'Invalid Estimated LeadTime',
        qaCodeForGOMBUValidation: 'QA Code column is only applicable for GOMBU',
        qaCodeValidation: 'Invalid QA Code',
        supplierValidation: 'Supplier(Purchasing) column is only applicable for MCBU, RBU and SJVBU',
        supplierPartNumValidation: 'Supplier Part Number column is only applicable for MCBU, RBU and SJVBU',
        MaxMinValidation: 'Max stock level must be greater than or equal to the ROP (Min)',
        spkForSJVBUValidation: 'SPK column is only applicable for SJVBU',
        plantAndStorageLocationDuplication: 'Duplicate Plant and Storage Location were found in the different line of Excel spreadsheet',
        plantValidation: 'Invalid plant selected or wrong business unit plant used',
        buStorageLocationValidation: 'Invalid storage location selected or wrong business unit storage location used',
        buPlantStorageValidation: 'Invalid plant and storage location for a business unit',
        invalidMaterialNumber: 'Invalid Material Number',
        chemalertStockNumberValidation: 'ChemAlert Approval Required should be set to Yes',
        stockingLocationsValidation:  'This stocking location is not recommended',
        stockingLocationMinMaxError: 'Maximum must be greater than the Minimum in each stocking location',
    }
}
