import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { RoleDto } from 'src/app/features/setup/models/userandrole/role.model';
import { RoleService } from 'src/app/features/setup/services/role.service';
import { CommonService } from '../../services/common.service';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { LoaderSize, LoaderThemeColor, LoaderType } from '@progress/kendo-angular-indicators';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-config-role',
    templateUrl: './config-role.component.html',
    styleUrls: ['./config-role.component.scss']
})
export class ConfigRoleComponent implements OnInit {
    roleData: RoleDto[] = [];
    userData: UserDto | undefined;
    selectedRole: string | undefined = '';
    isLoader = true;
    userList: any[] = [];
    result: any = [];
    public loader =
        {
            type: <LoaderType>"converging-spinner",
            themeColor: <LoaderThemeColor>"info",
            size: <LoaderSize>"medium",
        };

    constructor(private roleService: RoleService, public dialog: DialogRef,
        public commonService: CommonService, private sarUserService: SarUsersService,
        public router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.getUserRoles();
    }

    getUserRoles() {
        this.isLoader = true;
        this.roleService.getRoleByEmail<UserDto>(this.commonService.getUserEmailId()).subscribe((outputDtata: UserDto) => {
            this.userData = outputDtata;

            this.sarUserService.getSARUsers().subscribe((response: any[]) => {
                this.userList = Object.assign([], response);

                // this.result = this.userList.filter((obj: any) => {
                //   return obj.cai.toLocaleLowerCase() === this.userData?.cai?.toLocaleLowerCase() && obj.isActive === true;
                // }); /* Commentted for 7136137: CAI Deprecation */

                this.result = this.userList.filter((obj: any) => {
                    return obj.objectId === this.userData?.objectId && obj.isActive === true;
                }); /* New code for 7136137: CAI Deprecation */

                this.isLoader = false;
                if (this.result.length == 0) {
                    this.dialog.close({ action: 'Cancel', value: { roleName: '', BUName: this.userData?.businessUnitname } });
                    return false;
                }
            });

            if (this.userData && this.userData.roleDtos) {
                this.roleData = this.userData.roleDtos;
                this.selectedRole = this.userData.roleDtos[0].name;
            } else {
                this.dialog.close({ action: 'Cancel', value: { roleName: '', BUName: this.userData?.businessUnitname } });
            }

        });
    }

    Save() {
        this.isLoader = false;
        this.dialog.close({ action: 'Save', value: { roleName: this.selectedRole, BUName: this.userData?.businessUnitname } });
    }

    cancel() {
        this.isLoader = false;
        this.dialog.close({ action: 'Cancel', value: { roleName: this.selectedRole, BUName: this.userData?.businessUnitname } });
    }

}