<div class="contentBox">
    <h1 class="content-top"><a class="c-left" routerLink="/setup"><span
                class="fa fa-fw fa-angle-left"></span></a>Stocking Locations
    </h1>
    <div class="text-align-right mt-20">
        <button kendoButton class="border-button ml-20" (click)="export()"> Export </button>
        <button kendoButton class="border-button ml-20" (click)="import()">Import</button>
        <button kendoButton class="border-button ml-20" (click)="manage(true,0,null)">
            Create New Stocking Location
        </button>
    </div>
    <div class="search mt-20">

        <kendo-textbox [style.width.px]="400" [(ngModel)]="searchText"></kendo-textbox>

        <button kendoButton class="primary-button ml-20" (click)="search()"> Search </button>
        <button kendoButton class="border-button ml-20" (click)="clear()"> Reset </button>
    </div>
    <kendo-grid class="mt-20" [filterable]="true" [kendoGridBinding]="gridData" [pageSize]="10" [resizable]="true" [pageable]="true">
        <kendo-grid-column field="code" title="Branch" [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="javascriipt:void();" 
                    (click)="manage(false, dataItem.id, dataItem)">{{dataItem.code}}
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="isStockingLocationString" title="Is Stocking Location" [width]="40"></kendo-grid-column>
        <kendo-grid-column field="description" title="Branch Description" [width]="40">
        </kendo-grid-column>
        <kendo-grid-column field="company" title="Company" [width]="60"></kendo-grid-column>
        <kendo-grid-column field="location" title="Location" [width]="60">
        </kendo-grid-column>
        <kendo-grid-column field="asset" title="Asset" [width]="40">
        </kendo-grid-column>
        <kendo-grid-column field="assetDescription" title="Asset Description" [width]="40">
        </kendo-grid-column>
        <kendo-grid-column field="assetFunction" title="Asset Function" [width]="40">
        </kendo-grid-column>
        <kendo-grid-column field="assets" title="Asset Groups" [width]="40">
        </kendo-grid-column>
    </kendo-grid>
</div>