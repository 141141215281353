import { Component, OnInit } from '@angular/core';
import { NewActionInputParam } from '../../models/common/new-action-input-param.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialNumberJdeService } from '../../services/material-number-jde.service';
import { MaterialNumberDto } from '../../models/change-sar/material-number.model';
import { TempActionService } from '../../services/temp-action.service';
import * as saveAs from 'file-saver';
import * as ExcelJS from 'exceljs';
import { JdeMaterialDto } from '../../models/change-sar/jde-material.model';

@Component({
  selector: 'app-inventory-level-update-generate-excel',
  templateUrl: './inventory-level-update-generate-excel.component.html',
  styleUrls: ['./inventory-level-update-generate-excel.component.scss']
})
export class InventoryLevelUpdateGenerateExcelComponent implements OnInit {
  newActionInputParam: NewActionInputParam = {};
  assetName: string = '';
  enteredMaterialNumbers: string = '';
  validMaterialNumbers: string[] = [];
  note: string[] = [];
  itemsValidated: boolean = false;
  selectedMaterialNumbers: string[] = [];
  mainFileName: string = 'BulkInventoryItemUpdate';
  excelFileName: string = '';
  excelData: JdeMaterialDto[] = [];

  constructor(private commonService: CommonService, public router: Router, private route: ActivatedRoute, private tempActionService: TempActionService, private materialNumberJdeService: MaterialNumberJdeService) {const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.newActionInputParam.isNew = stateData['isNew'];
      this.newActionInputParam.requestId = stateData['requestId'];
      this.newActionInputParam.requestNumber = stateData['requestNumber'];
      this.newActionInputParam.actionNumber = stateData['actionNumber'];
      this.newActionInputParam.changeType = stateData['changeType'];
      this.newActionInputParam.requestIssueId = stateData['requestIssueId'];
      this.newActionInputParam.assetName = stateData['assetName'];
      this.assetName = stateData['assetName'];
      sessionStorage.setItem('inventory-level-update-add-new-item', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('inventory-level-update-add-new-item')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.newActionInputParam.isNew = sessionState.isNew;
        this.newActionInputParam.requestId = sessionState.requestId;
        this.newActionInputParam.requestNumber = sessionState.requestNumber;
        this.newActionInputParam.requestIssueId = sessionState.requestIssueId;
        this.newActionInputParam.changeType = sessionState.changeType;
        this.newActionInputParam.actionNumber = sessionState.actionNumber;
        this.newActionInputParam.assetName = sessionState.assetName;
      }
    } }

  ngOnInit(): void {
    this.excelFileName = this.mainFileName + this.commonService.getUserBUName();
  }

  onValidate(): void {
    this.note = [];
    let originalData: string[] = this.enteredMaterialNumbers.split(/[\n\t\s,]+/).filter(num => num !== '');
    let data: string[] = this.removeduplicates(originalData);
    let wrongMaterialNumbers: string[] = [];
    let notificationMessage: string = '';
    let messageType: 'success' | 'warning' | 'error' = 'success';

    this.materialNumberJdeService.validateMaterialNumber(data).subscribe(
      (response: MaterialNumberDto) => {
        this.validMaterialNumbers = response.validMaterialNumbers;
        if (this.validMaterialNumbers.length > 0) { this.itemsValidated = true; }
        wrongMaterialNumbers = data.filter((x: string) => !this.validMaterialNumbers.includes(x));
        notificationMessage = `${this.validMaterialNumbers.length} valid material numbers were added, `;

        if (wrongMaterialNumbers.length > 0) {
          notificationMessage += `Wrong material numbers count: ${wrongMaterialNumbers.length} were ignored because of the wrong addition`;
          messageType = 'warning';
        }
        else {
          messageType = 'success';
        }
        this.commonService.showNotificationMessage(notificationMessage, messageType, 'center');
        this.enteredMaterialNumbers = '';
      });
  }

  removeduplicates(table: any[]) {
    const uniqueArray = table.reduce((acc, current) => {
      if (!acc.includes(current))
        acc.push(current);
      return acc
    }, []);
    return uniqueArray;
  }

  navToBack() {
    this.router.navigate(['./newaction'], { state: { isNew: this.newActionInputParam.isNew, requestId: this.newActionInputParam.requestId, requestNumber: this.newActionInputParam.requestNumber, requestIssueId: this.newActionInputParam.requestIssueId, actionNumber: this.newActionInputParam.actionNumber, changeType: this.newActionInputParam.changeType, assetName: this.newActionInputParam.assetName }, relativeTo: this.route.parent });
  }

  downloadExcelTemplate(): void {
    if (this.selectedMaterialNumbers.length === 0) {
      this.commonService.showNotificationMessage('Please add the validated material numbers', 'error', 'center');
    }
    else {
      this.downloadRawAndTemplateFiles();
      this.navToBack();
    }
  }

  downloadRawAndTemplateFiles() {
    this.tempActionService.fetchBlobUrl(this.excelFileName).subscribe((responseUrl: string) => {
      this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
        const blob = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
        const reader = new FileReader();
      
        reader.onload = async (e: any) => {
          const arrayBuffer = e.target.result;
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(arrayBuffer);
          const worksheet = workbook.getWorksheet(1);
          const list = workbook.getWorksheet(2);

          const jdeItemNumberColIndex = 2;
          const branchPlantColIndex = 3;
          const assetForUnitPriceColIndex = 7;

          this.materialNumberJdeService.getJdeMaterials(this.selectedMaterialNumbers).subscribe(async (response: JdeMaterialDto[]) => {
            this.excelData = response;
            const returnedMaterialNumbers = Array.from(new Set(response.map(item => item.materialNumber)));

            // Update main sheet
            returnedMaterialNumbers.forEach((item, index) => {
              const row = worksheet!.getRow(index + 3);
              row.getCell(jdeItemNumberColIndex).value = item;
              row.getCell(branchPlantColIndex).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [`=OFFSET(Lists!$C$1, MATCH($B${index + 3}, Lists!$B:$B, 0) - 1, 0, COUNTIF(Lists!$B:$B, $B${index + 3}))`]
              };
              row.getCell(assetForUnitPriceColIndex).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [`=OFFSET(Lists!$C$1, MATCH($B${index + 3}, Lists!$B:$B, 0) - 1, 0, COUNTIF(Lists!$B:$B, $B${index + 3}))`]
              };
            });

            // Update hidden "Lists" sheet
            this.excelData.forEach((item, index) => {
              const row = list!.getRow(index + 1);
              row.getCell(1).value = `${item.materialNumber}|${item.branchPlant}`;
              row.getCell(2).value = item.materialNumber;
              row.getCell(3).value = item.branchPlant;
              row.getCell(4).value = item.unitCost;
            });
    
            const updatedBuffer = await workbook.xlsx.writeBuffer();
            const updatedBlob = new Blob([updatedBuffer], { type: "application/vnd.ms-excel;charset=utf-8" });
            
            saveAs(updatedBlob, this.mainFileName + '.xlsx');
          });
        };

        reader.readAsArrayBuffer(blob);
      });
    });
  }
}
