export class InventoryLevelUpdateDto {
    id: number;
    requestId: number;
    requestIssueId?: number;
    requestIssueTypeId: number;
    lineNumber?: number;
    materialNumber: number;
    asset: string;
    unitPrice: number;
    annualUsageForecast: number;
    stockCount: number;
    totalValue: number;
    changeInValue: number;
    status: string;
    itemStatus?: string;
    itemDescription: string;
    requesterNotes: string;
    createdBy: string;
    modifiedBy?: string;
    comment?: string;
    inventoryLevelUpdateGridData: InventoryLevelUpdateGridDataDto[];
}

export class InventoryLevelUpdateImportFromExcelDto {
    id: number;
    requestId: number;
    requestIssueId?: number;
    requestIssueTypeId: number;
    lineNumber?: number;
    materialNumber: number;
    asset: string;
    unitPrice: number;
    annualUsageForecast: number;
    totalValue: number;
    branchPlant: string;
    minimum: number;
    maximum: number;
    requesterNotes: string;
    createdBy: string;
}

export class InventoryLevelUpdateItemDto {
    description: string;
    unitPrice: number;
}

export class InventoryLevelUpdateRowDataDto {
    description: string;
    existingMinimum: number;
    existingMaximum: number;
}

export class InventoryLevelUpdateGridDataDto {
    id: number;
    code: string;
    description: string;
    existingMinimum: number;
    recommendedMinimum: number;
    existingMaximum: number;
    recommendedMaximum: number;
    estimatedStockHoldingValue: number;
}

export class InventoryLevelUpdateResponseDto {
    requestLineId: number;
    requestId: number;
    requestIssueId?: number;
}