export class StockingLocation {
    id: number;
    code?: string;
    description?: string;
    company?: string;
    location?: string;
    asset?: string;
    assetDescription?: string;
    assetFunction?: string;
    isActive: boolean;
    isPQ: boolean;
    isNCR: boolean;
    isSAR: boolean;
    isStockingLocation: boolean;
    isStockingLocationString: string;
    assets?: string;
    createdBy?: string;
    createdDateTime?: Date;
    modifiedBy?: string;
    modifiedDateTime?: Date;
    operation?: number;
}