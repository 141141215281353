import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { GraphService } from 'src/app/core/services/graph.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Group } from '../../models/group/group.model';
import { Role } from '../../models/role/role.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { SARUsers } from '../../models/sarusers/sarusers.model';
import { SARUsersConstant } from 'src/app/core/constants/sar-users.constants';
import { SarUsersService } from '../../services/sar-users.service';

@Component({
  selector: 'app-sar-users',
  templateUrl: './sar-users.component.html',
  styleUrls: ['./sar-users.component.scss']
})
export class SarUsersComponent implements OnInit {
  constructor(private sarUserService: SarUsersService,
    private dialogService: DialogService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private graphService: GraphService,
    public router: Router) { }

  public gridData: SARUsers[] = [];
  public globalGridData: SARUsers[] = [];
  public checked: true = true;
  public searchText: string = '';
  public showInactive = false;

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getSARUser();
  }

  getSARUser() {
    this.sarUserService.getSARUsers().subscribe((response: SARUsers[]) => {
      this.globalGridData = Object.assign([], response);
      this.updateGrid();
    });
  }

  getRoleAndGroupNames(element: Role[] | Group[], columnName: string, dataItem: SARUsers): string {

    if (element.length > 0) {
      const arr: string[] = [];
      element.forEach(el => { arr.push(<string>el["name"]) }
      )
      columnName == 'groupsArr' ? dataItem.groupsArr = arr.toString() : dataItem.rolesArr = arr.toString();
      return arr.toString();
    }
    return '';
  }

  updateGrid() {
    this.gridData = this.commonService.setupGobalSearch(this.globalGridData, this.showInactive, SARUsersConstant.searchFieldName, this.searchText, SARUsersConstant.email); //, SARUsersConstant.cai);
  }

  cancelSearch() {
    this.searchText = '';
    this.updateGrid();
  }

  activeChanged(value: boolean) {
    this.showInactive = value;
    this.updateGrid();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  manage(isCreate: boolean, 
         isActive: boolean, 
         name: string, 
         id: number, 
        //  cai: string, /* Commentted for 7136137: CAI Deprecation */
         objectId: string,
         email: string, 
         provisionId: string, 
         externalId: string, 
         businessUnitname: string, 
         businessUnitId: number, 
         roleDtos: [], groupDtos: []) {
    this.router.navigate(["../ManageSARUsers"], {
      state: {
        'isCreate': isCreate,
        'id': id,
        'name': name,
        'isActive': isActive,
        // 'cai': cai, /* Commentted for 7136137: CAI Deprecation */
        'objectId': objectId,
        'username': name,
        'email': email,
        'provisionId': provisionId,
        'externalId': externalId,
        'businessUnitname': businessUnitname,
        'businessUnitId': businessUnitId,
        'roleDtos': roleDtos,
        'groupDtos': groupDtos
      },
      relativeTo: this.route
    },
    );
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let sarUsers: SARUsers[] = arr.map((item: any) => {
          return this.mapUserDtoFromExcel(item);
        });
        this.importExcelData(sarUsers);
      }
    });
  }

  splitString(val: string): string[] {
    return val.split(',');
  }

  importExcelData(sarUsers: SARUsers[]) {
    forkJoin(
       sarUsers.map(user => this.GrphUserInfo(user))
    )
      .subscribe(
        updatedData => {
          this.sarUserService.importExcelData(updatedData).subscribe((response: SARUsers) => {
            this.commonService.showNotificationMessage(SARUsersConstant.bulkInsertMessage, 'success', 'right')
            this.getSARUser();
          });
        }
      );
  }
  
  GrphUserInfo(sarUser: SARUsers): Observable<SARUsers> {
    return this.graphService.getAdUserNameByEmail(sarUser.email ?? "").pipe(
      map(response => {
        sarUser.userName = response.value[0].displayName + ' ' + response.value[0].surname;
        sarUser.provisionId = <string>response.value[0].extension_39c7d3e68666465dab296ee0fc538118_cvx_ProvisioningID;
        sarUser.email = <string>response.value[0].userPrincipalName;
        sarUser.externalId = <string>response.value[0].id;
        sarUser.objectId = <string>response.value[0].id;
        return sarUser;
      })
    )
  }

  mapRoleDtoFromExcel(item: any): Role[] {
    var roleDtos: Role[] = [];
    var rolenames = this.splitString(item['Access Group Roles']);
    rolenames.forEach(function (name: string) {
      var roleDto = new Role();
      roleDto.name = name;
      roleDto.createdBy = item['Created By'];
      roleDto.modifiedBy = item['Modified By'];
      roleDto.description = name;
      roleDtos.push(roleDto);
    });
    return roleDtos;
  }

  mapGroupDtoFromExcel(item: any): Group[] {
    var groupNames = this.splitString(item['Group Names']);
    var groupDtos: Group[] = [];
    
    groupNames.forEach(function (name: string) {
      var groupDto = new Group();
      groupDto.name = name;
      groupDto.createdBy = item['Created By'];
      groupDto.modifiedBy = item['Modified By'];
      groupDto.description = name;
      groupDto.businessunitName = item['Business Unit'];
      groupDto.groupTypeName = name,
        groupDtos.push(groupDto);
    });
    return groupDtos;
  }

  mapUserDtoFromExcel(item: any): SARUsers {
    var sarUser: SARUsers = {
      id: 0,
      name: item['Name'].toString(),
      userName: item['Name'].toString(),
      // cai: item['CAI'].toString(), /* Commentted for 7136137: CAI Deprecation */
      objectId: item['ObjectId'],
      isActive: item['Is Active'],
      businessUnitname: item['Business Unit'], //excel code & multiselect
      businessUnitId: 0,    //excel code & multiselect
      email: item['Email'],            //excel code & multiselect
      provisionId: '',      //excel code & multiselect
      externalId: '',       //excel code & multiselect
      groupDtos: this.mapGroupDtoFromExcel(item),        //excel code & multiselect
      roleDtos: this.mapRoleDtoFromExcel(item),         //excel code & multiselect
      roleIds: [],          //excel code & multiselect
      groupIds: [],        //excel code & multiselect
      modifiedBy: item['Modified By'],
      createdBy: item['Created By'],
      createdDateTime: undefined,
      modifiedDateTime: undefined,
    };

    return sarUser;
  }

}