<div class="sar-contentBox">
    <h2 class="heading content-top">Stock Authorization Request</h2>
    <section class="sar-container">
        <!-- Left Part -->
        <section class="in-side-nav">
            <button kendoButton class="primary-button w-100 " href="Javascript:void(0);" *ngIf="canCreateNewSAR()" (click)="manage(true,true,0)">
                Create new SAR </button>

            <!-- Drop down -->
            <select class="selectlist mt-20" [(ngModel)]="_sarSelection" (change)="onSarSelectChange()">
                <option *ngFor="let group of this.userGroups" [value]="group.id">{{group.name}}</option>
            </select>
            <!-- Drop down -->

            <div class="mt-15">
                <h5 *ngIf="_sarSelection==sarSelectType.mySar" class="mt-5 mb-5">SARs CREATED BY ME</h5>
                <h5 *ngIf="_sarSelection==sarSelectType.myBu" class="mt-5 mb-5">SARs CREATED BY MY BU</h5>
                <h5 *ngIf="assignedToMyGroup" class="mt-5 mb-5">SARs ASSIGNED TO MY GROUP</h5>
                <div>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.pendingStockingApproval  && (!isAssignedToMeStateSelected || _sarSelection==sarSelectType.myBu)}"
                        (click)="onStateChange(false,states.pendingStockingApproval)">
                        <div class="sar-icon">
                            <img class="icon" alt="icons"
                                [src]="selectedStateId===states.pendingStockingApproval  && (!isAssignedToMeStateSelected || _sarSelection==sarSelectType.myBu) ? '../../../../../assets/icons/rfcoselected.svg' : '../../../../../assets/icons/rfcodeselected.svg'" />
                            <p> Pending Approval</p>
                        </div>
                        <p>{{requestsCount.pendingApproval}}</p>
                    </a>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.pendingMMD  && (!isAssignedToMeStateSelected || _sarSelection==sarSelectType.myBu)}"
                        (click)="onStateChange(false,states.pendingMMD)">
                        <div class="sar-icon">
                            <img class="icon" alt="icons"
                                [src]="selectedStateId===states.pendingMMD  && (!isAssignedToMeStateSelected || _sarSelection==sarSelectType.myBu) ? '../../../../../assets/icons/rfcoselected.svg' : '../../../../../assets/icons/rfcodeselected.svg'" />
                            <p> Pending MMD</p>
                        </div>

                        <p>{{requestsCount.pendingMMD}}</p>
                    </a>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.rejected  && !isAssignedToMeStateSelected}"
                        (click)="onStateChange(false,states.rejected)">
                        <div class="sar-icon">
                            <img class="icon-size" alt="icons"
                                [src]="selectedStateId===states.rejected  && !isAssignedToMeStateSelected ? '../../../../../assets/icons/cancelselected.svg' : '../../../../../assets/icons/canceldeselected.svg'" />
                            <p> Rejected</p>
                        </div>
                        <p>{{requestsCount.rejected}}</p>
                    </a>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.draft  && !isAssignedToMeStateSelected}"
                        (click)="onStateChange(false,states.draft)">
                        <div class="sar-icon">
                            <img class="icon-size" alt="icons"
                                [src]="selectedStateId===states.draft  && !isAssignedToMeStateSelected ? '../../../../../assets/icons/draftselected.svg' : '../../../../../assets/icons/draftdeselected.svg'" />
                            <p> Draft</p>
                        </div>
                        <p>{{requestsCount.draft}}</p>
                    </a>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.completed  && !isAssignedToMeStateSelected}"
                        (click)="onStateChange(false,states.completed)">
                        <div class="sar-icon">
                            <img class="icon-size" alt="icons"
                                [src]="selectedStateId===states.completed  && !isAssignedToMeStateSelected ? '../../../../../assets/icons/completeselected.svg' : '../../../../../assets/icons/completedeselected.svg'" />
                            <p> Completed</p>
                        </div>
                        <p>{{requestsCount.completed}}</p>
                    </a>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.cancelled  && !isAssignedToMeStateSelected}"
                        (click)="onStateChange(false,states.cancelled)">
                        <div class="sar-icon">
                            <img class="icon-size" alt="icons"
                                [src]="selectedStateId===states.cancelled  && !isAssignedToMeStateSelected ? '../../../../../assets/icons/cancelselected.svg' : '../../../../../assets/icons/canceldeselected.svg'" />
                            <p> Cancelled</p>
                        </div>
                        <p>{{requestsCount.cancelled}}</p>
                    </a>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.all  && !isAssignedToMeStateSelected}"
                        (click)="onStateChange(false,states.all)">
                        <div class="sar-icon">
                            <img class="icon-size" alt="icons"
                                [src]="selectedStateId===states.all  && !isAssignedToMeStateSelected ? '../../../../../assets/icons/allselected.svg' : '../../../../../assets/icons/alldeselected.svg'" />
                            <p> All</p>
                        </div>
                        <p>{{requestsCount.all}}</p>
                    </a>
                </div>
            </div>
            <div class="mt-15" *ngIf="_sarSelection===sarSelectType.mySar">
                <h5 class="mt-5 mb-5">SARs ASSIGNED TO ME</h5>
                <div>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.pendingStockingApproval && isAssignedToMeStateSelected}"
                        (click)="onStateChange(true,states.pendingStockingApproval)">
                        <div class="sar-icon">
                            <img class="icon" alt="icons"
                                [src]="selectedStateId===states.pendingStockingApproval && isAssignedToMeStateSelected ? '../../../../../assets/icons/rfcoselected.svg' : '../../../../../assets/icons/rfcodeselected.svg'" />
                            <p> Pending Approval</p>
                        </div>

                        <p>{{requestsAssignedToMeCount.pendingApproval}}</p>
                    </a>
                    <a class="l-item"
                        [ngClass]="{'selected':selectedStateId===states.pendingMMD  && isAssignedToMeStateSelected}"
                        (click)="onStateChange(true,states.pendingMMD)">
                        <div class="sar-icon">
                            <img class="icon" alt="icons"
                                [src]="selectedStateId===states.pendingMMD  && isAssignedToMeStateSelected ? '../../../../../assets/icons/rfcoselected.svg' : '../../../../../assets/icons/rfcodeselected.svg'" />
                            <p> Pending MMD</p>
                        </div>
                        <p>{{requestsAssignedToMeCount.pendingMMD}}</p>
                    </a>
                </div>
            </div>

        </section>
        <!-- Right Part -->
        <section class="in-side-main">
            <!-- Navigation -->
            <div class="d-flex j-c-s ml-10">
                <a class="main-l-item" [ngClass]="{'typeSelected':selectedActionTypeId===actionTypes.all}"
                    (click)="onActionTypeChange(actionTypes.all)">
                    <p>All </p>
                    <p class="count">{{requestsIssueTypeCount.all}}</p>
                </a>
                <a class="main-l-item" [ngClass]="{'typeSelected':selectedActionTypeId===actionTypes.addNewItem}"
                    (click)="onActionTypeChange(actionTypes.addNewItem)">
                    <p>Add New Items</p>
                    <p class="count">{{requestsIssueTypeCount.addNewItems}}</p>
                </a>
                <a class="main-l-item" [ngClass]="{'typeSelected':selectedActionTypeId===actionTypes.changeData}"
                    (click)="onActionTypeChange(actionTypes.changeData)">
                    <p>Change Data</p>
                    <p class="count">{{requestsIssueTypeCount.changeData}}</p>
                </a>
                <a class="main-l-item"
                    [ngClass]="{'typeSelected':selectedActionTypeId===actionTypes.extensionMRPUpdate}"
                    (click)="onActionTypeChange(actionTypes.extensionMRPUpdate)">
                    <p>Extension/MRP Update</p>
                    <p class="count">{{requestsIssueTypeCount.extentionMRPUpdate}}</p>
                </a>
                <div class="search-box ml-5">
                    <kendo-textbox [style.width.px]="170" placeholder="Filter SARs" [(ngModel)]="searchText"
                        (keydown.enter)="search()">
                        <ng-template kendoTextBoxSuffixTemplate>
                            <kendo-icon name="search" (click)="search()"></kendo-icon>
                        </ng-template>
                    </kendo-textbox>
                </div>
            </div>
            <!-- Grid -->
            <ng-template #template let-anchor>
                <span>{{ anchor.nativeElement.innerText }}</span>
            </ng-template>
            <div class="grid-box" kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                (mouseover)="showTooltip($event)">
                <kendo-grid [kendoGridBinding]="gridData" [pageSize]="10" #grid="kendoGrid" [resizable]="true"
                    [pageable]="true">
                    <kendo-grid-column field="sarId" title="SAR ID">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a href="javascript:void();"
                                (click)="manage(false,false,dataItem.requestId)">{{dataItem.sarId}}
                            </a>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="item" title="Item">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div *ngIf="dataItem.item ==='' ">-</div>
                            <div *ngIf="dataItem.item !=='' ">
                                <a href="javascript:void();"
                                    (click)="navViewAction(rowIndex,dataItem)">{{dataItem.item}}
                                </a>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Action">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div *ngIf="dataItem.action ==='' ">-</div>
                            <div *ngIf="dataItem.action !=='' ">{{dataItem.action}}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="priority" title="Priority"></kendo-grid-column>
                    <kendo-grid-column title="Status" [width]="250">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div
                                [ngClass]="{'default-button status-btn':true,'draftbttn':dataItem.status === 'Draft', 'rejectedbttn':dataItem.status === 'Rejected',
                        'completedbttn':dataItem.status === 'Completed','cancelledbttn':dataItem.status === 'Cancelled'}">
                                {{dataItem.status}}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Assigned To">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div *ngIf="dataItem.assignedToUserName !== '' ">{{dataItem.assignedToUserName}}
                                <span *ngIf="dataItem.assignedToGroup !== '' ">,
                                </span>
                            </div>
                            <div *ngIf="dataItem.assignedToGroup !== '' ">{{dataItem.assignedToGroup}}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Created By">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.createdByUserName}}
                            <br />
                            {{dataItem.createdByEmail}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Created On">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.createdOn | date: dateFormat}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="age" title="Age">
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </section>
    </section>
</div>