import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { FetchDataCallback } from "@progress/kendo-angular-grid";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificationService } from "@progress/kendo-angular-notification";
import { Observable } from "rxjs";
import { process } from '@progress/kendo-data-query';
import { SarConfirmationBoxComponent } from "../components/sar-confirmation-box/sar-confirmation-box.component";
import { saveAs } from "file-saver";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor(private notificationService: NotificationService, private http: HttpClient,
        private dialogService: DialogService) {
    }

    showNotificationMessage(messageText: string,
        messageType: 'none' | 'success' | 'warning' | 'error' | 'info',
        position: 'left' | 'center' | 'right', _duration?: number) {
        this.notificationService.show({
            content: messageText,
            cssClass: "button-notification",
            animation: { type: "slide", duration: _duration ? _duration : 200 },
            position: { horizontal: position, vertical: "top" },
            type: { style: messageType, icon: true }
        });
    }

    public showConfirmation(actionName: string, confMessage: string, isRejectedCancel?: boolean) {
        const dialogRef: DialogRef = this.dialogService.open({
            content: SarConfirmationBoxComponent
        });
        const sarInfo = dialogRef.content.instance as SarConfirmationBoxComponent;
        sarInfo.confirmMessage = confMessage;
        sarInfo.headerOption = actionName;
        sarInfo.isRejectedCancel = isRejectedCancel;
        return dialogRef.result;
    }

    clearSession() {
        sessionStorage.clear();
    }

    setupGobalSearch<T>(data: T[], isActive: boolean, fieldName: string,
        fieldValue: string, optionalFieldName?: string) {
        let gridData;

        if (optionalFieldName) {
            if (isActive) {
                gridData = data.filter((x: any) => (x[fieldName]?.toLocaleLowerCase()
                    .includes(fieldValue.toLocaleLowerCase()) || x[optionalFieldName]?.toLocaleLowerCase()
                        .includes(fieldValue.toLocaleLowerCase())));
                return gridData;
            }
            gridData = data.filter((x: any) => (x[fieldName]?.toLocaleLowerCase()
                .includes(fieldValue.toLocaleLowerCase()) || x[optionalFieldName]?.toLocaleLowerCase()
                    .includes(fieldValue.toLocaleLowerCase())) && x.isActive === true)
            return gridData;
        }

        if (isActive) {
            gridData = data.filter((x: any) => x[fieldName]?.toLocaleLowerCase()
                .includes(fieldValue.toLocaleLowerCase()));
            return gridData;
        }

        gridData = data.filter((x: any) => x[fieldName]?.toLocaleLowerCase()
            .includes(fieldValue.toLocaleLowerCase()) && x.isActive === true)

        return gridData;
    }

    getUserEmailId(): string {
        return JSON.parse(sessionStorage.getItem("userPrincipalName")!);
    }

    getUserRoleName(): string {
        return sessionStorage.getItem("roleName")!;
    }

    getUserID(): string {
        return sessionStorage.getItem("userId")!;
    }

    getUserBUName(): string {
        return sessionStorage.getItem("userBUName")!;
    }

    isNullOrEmpty(value: string) {
        if (value == null || value === '') {
            return true;
        }
        return false;
    }

    isNullOrEmptyOrUndefined(value: string | undefined | number) {
        if (value == null || value === '' || value === undefined || value === 0 || value === '0') {
            return true;
        }
        return false;
    }

    isNullOrEmptyOrUndefinedOnly(value: string | undefined | number) {
        if (value == null || value === '' || value === undefined) {
            return true;
        }
        return false;
    }

    isNullOrUndefined(value: any) {
        if (value == null || value === undefined) {
            return true;
        }
        return false;
    }

    isEmptyOrUndefined(value: string) {
        if (value === undefined || value === '') {
            return true;
        }
        return false;
    }

    public exportGridData(gridData: any, sortFieldName: string, sortType: 'asc' | 'desc'): FetchDataCallback {
        return () => {
            const result: ExcelExportData = {
                data: process(gridData, { sort: [{ field: sortFieldName, dir: sortType }] }).data
            };
            return result;
        }
    }

    isNAN(value: any): boolean {
        let data = parseInt(value);
        if (Number.isNaN(data)) {
            return true;
        }
        else {
            if (typeof value === 'string' && value.length !== data.toString().length) {
                return true;
            }
            return false;
        }
    }

    fetchStaticFile(filePath: string): Observable<any> {
        return this.http.get(filePath, { responseType: 'blob' });
    }

    downloadFile(file: Blob, fileName: string) {
        saveAs(file, fileName);
    }

    public exportAsExcelFileWhenDataCall(data: any[], columns: any, excelFileName: string, isReturn?: boolean, listData?: any): any {
        const excelBuffer: any = this.prepareExcelData(data, columns, listData);
        if (isReturn) {
            return this.saveAsExcelFile(excelBuffer, excelFileName, isReturn);
        }
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    public exportAsExcelFile(json: any, columns: any, excelFileName: string, isReturn?: boolean, listData?: any): any {
        let resdata = json.map((data: any) => this.flattenObject(data, columns));

        const excelBuffer: any = this.prepareExcelData(resdata, columns, listData);
        if (isReturn) {
            return this.saveAsExcelFile(excelBuffer, excelFileName, isReturn);
        }
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private prepareExcelData(data: any[], columns: any, listData: any) {
        let finalData = this.renameKeys(data, columns);
        type newType = XLSX.WorkSheet;

        const worksheet: newType = XLSX.utils.json_to_sheet(finalData);
        let workbook: XLSX.WorkBook;
        if (listData) {
            let listSheet: newType = XLSX.utils.json_to_sheet(listData);
            workbook = {
                Sheets: { 'data': worksheet, 'list': listSheet! },
                SheetNames: ['data', 'list']
            };
        }
        else {
            worksheet['!cols'] = this.fitToColumn(finalData);
            workbook = {
                Sheets: { 'data': worksheet },
                SheetNames: ['data']
            };
        }
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        return excelBuffer;
    }

    fitToColumn(arrayOfArray: any) {
        return arrayOfArray.map((a: any, i: any) => ({
            wch: Math.max(...arrayOfArray.map(
                (a2: any) => a2[i] ? a2[i].toString().length : 20))
        }));
    }

    flattenObject(ob: any, coulnnames: any) {
        let toReturn: any = {};
        for (let i in ob) {
            if (!ob.hasOwnProperty(i)) continue;
            if ((typeof ob[i]) == 'object' && ob[i] !== null) {
                let flatObject = this.flattenObject(ob[i], coulnnames);
                for (var x in flatObject) {
                    if (x.toLocaleLowerCase().search('id') >= 0) continue;
                    if (!flatObject.hasOwnProperty(x)) continue;
                    if (Object.keys(coulnnames).some(c => c === x)) {
                        toReturn[x] = flatObject[x];
                    }
                }
            } else {
                if (Object.keys(coulnnames).some(c => c === i)) {
                    toReturn[i] = ob[i];
                }
            }
        }
        return toReturn;
    };

    renameKeys(data: any[], dynamicColumns: any): any {
        let columnNames = Object.keys(dynamicColumns);
        data.forEach((obj: { [x: string]: any; }) => {
            Object.keys(obj).forEach((key, index) => {
                let keyIndex = columnNames.indexOf(key);
                if (keyIndex >= 0) {
                    obj[dynamicColumns[columnNames[keyIndex]]] = obj[key]
                    delete obj[key];
                }
            });
        });
        return data;
    }

    saveAsExcelFile(buffer: any, fileName: string, isReturn?: boolean): any {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        if (isReturn) {
            return data;
        }
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }

    formatDateTo_ddmmyyyy(date: Date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

}