<!-- Create component -->
<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1> <a class="c-left" (click)="navToBack()"> <span class="fa fa-fw fa-angle-left"></span></a>
            <span class="in-active"> Stock Authorization Request</span>
            <span class="in-active">
                {{newActionInputParam.requestNumber}}</span>
            <span class="active" *ngIf="newActionInputParam.isNew"><i class="fa fa-fw fa-angle-right"></i>New
                Item </span>
            <span class="active" *ngIf="!newActionInputParam.isNew"><i class="fa fa-fw fa-angle-right"></i>
                  Item {{lineItem}}
            </span>
        </h1>
        <div>
            <button kendoButton class="border-button ml-20 f-size-150" (click)="navToBack()"> Cancel </button>
            <button kendoButton class="primary-button ml-20 f-size-150" (click)="AddAndUpdate()"> {{buttonText}}
            </button>
        </div>
    </div>

    <section>
        <div class="mt-10 w-50">
            <kendo-label text="Material Number"></kendo-label><span class="mandatory">*</span> <br>
            <kendo-autocomplete [data]="materialData" [virtual]="virtual" #autocomplete [(ngModel)]="selectedMaterial"
                [disabled]="this.newActionInputParam.isNew === false" [filterable]="true"
                (filterChange)="filter($event)" (valueChange)="materialChange($event)"
                placeholder="Type 3 or more digits" class="mt-5"></kendo-autocomplete>
            <span *ngIf="matError" class="noValue"> Material number filed cannot be empty</span>
            <span *ngIf="matValidError" class="noValue"> Please select valid material number</span>
            <span *ngIf="materialNumValidationError" class="noValue"> Selected Material number does not belongs to
                current BU</span>
        </div>
        <div class="mt-10 w-50">
            <kendo-label text="Type of Data Change"></kendo-label><span class="mandatory">*</span> <br>
            <kendo-multiselect [data]="changeTypes" #multiselect id="multiselect" placeholder="-- Select Values --"
                class="mt-5" [(ngModel)]="selectedValues" (removeTag)="removeChangeType($event)"
                (valueChange)="changeTypeChange($event)" [disabled]="disabledTypes" textField="label" valueField="id"
                (removeTag)="removeItem($event)">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <span class="autoCompleteItem">
                        <button class="autoCompleteItem" [disabled]="dataItem.status">
                            {{ dataItem.label }}
                        </button>
                    </span>
                </ng-template>
            </kendo-multiselect>
        </div>
        <!-- for empty data -->
        <div class="mt-10" *ngIf="changeDataCurrentitems.length ===0 ">
            <kendo-grid [data]="changeDataCurrentitems">
                <ng-template kendoGridNoRecordsTemplate>
                    No items to show...
                </ng-template>
                <kendo-grid-column title="Change Type" field="changeType">
                </kendo-grid-column>
                <kendo-grid-column title="Branch plant/Other change type">
                </kendo-grid-column>
                <kendo-grid-column title="Old Data">
                </kendo-grid-column>
                <kendo-grid-column title="New Data">
                </kendo-grid-column>
            </kendo-grid>
        </div>
        <div class="mt-10" *ngFor="let item of changeDataCurrentitems!; let rowIndex = index">
            <kendo-grid [data]="item.changeTypeValues!" [hideHeader]="rowIndex !== 0">
                <kendo-grid-column title="Change Type" field="changeType">
                    <ng-template kendoGridCellTemplate>
                        {{changeDataCurrentitems[rowIndex]!.changeType}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Branch plant/Other change type">
                    <ng-template kendoGridCellTemplate let-dataItem
                        *ngIf="!isABU && (item.changeTypeId === changeDataEnum.other)">
                        <div
                            *ngIf="(editStatus === dataItem.status) ||  (dataItem.status === undefined || dataItem.status === '' )">
                            <kendo-textbox [(ngModel)]="dataItem.branchPlantOrOtherChangePlant"
                                class="{{getStyle(dataItem.hasPlanterror)}}">
                            </kendo-textbox>
                        </div>
                        <div *ngIf="editStatus && dataItem.status  && editStatus !== dataItem.status">
                            {{dataItem.branchPlantOrOtherChangePlant}}
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-gridRowIndex="rowIndex" *ngIf="!isABU">
                        <div *ngIf="dataItem.isBranchPlantApplicable &&  item.changeTypeId === changeDataEnum.leadTime">
                            <div
                                *ngIf="(editStatus === dataItem.status) ||  (dataItem.status === undefined || dataItem.status === '' )">
                                <kendo-dropdownlist [(ngModel)]="dataItem.branchPlantOrOtherChangePlant"
                                    class="{{getStyle(dataItem.hasPlanterror,dataItem.hasPlantDuplicateError )}}"
                                    kendoGridFocusable
                                    (valueChange)="plantChange($event,rowIndex,gridRowIndex,dataItem.branchPlants)"
                                    [defaultItem]="{ branchPlantCode: '-'}" [ngModelOptions]="{standalone: true}"
                                    textField="branchPlantCode" valueField="branchPlantCode" [valuePrimitive]="false"
                                    [data]="dataItem.branchPlants"></kendo-dropdownlist>
                                <br />
                                <span *ngIf="dataItem.hasPlantDuplicateError === true" class="noValue">Duplicate plant found</span>
                            </div>
                            <div *ngIf="(editStatus && dataItem.status && editStatus !== dataItem.status)">
                                {{dataItem.branchPlantOrOtherChangePlant}}
                            </div>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-gridRowIndex="rowIndex"
                        ngIf*="isABU && (item.changeTypeId === changeDataEnum.stockingType)">
                        <div>
                            <kendo-dropdownlist [(ngModel)]="dataItem.branchPlantOrOtherChangePlant"
                                class="{{getStyle(dataItem.hasPlanterror,dataItem.hasPlantDuplicateError )}}"
                                kendoGridFocusable
                                (valueChange)="plantChange($event,rowIndex,gridRowIndex,dataItem.branchPlants)"
                                textField="branchPlantCode" valueField="branchPlantCode" [valuePrimitive]="false"
                                [data]="dataItem.branchPlants"></kendo-dropdownlist>
                            <br />
                            <span *ngIf="dataItem.hasPlantDuplicateError === true" class="noValue">Duplicate plant
                                found</span>
                        </div>
                        <div *ngIf="(editStatus && dataItem.status && editStatus !== dataItem.status)">
                            {{dataItem.branchPlantOrOtherChangePlant}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Old Data">
                    <ng-template kendoGridCellTemplate let-dataItem *ngIf="item.changeTypeId === changeDataEnum.other">
                        <div
                            *ngIf="(editStatus === dataItem.status) ||  (dataItem.status === undefined || dataItem.status === '' )">
                            <div class="text-right">
                                <kendo-label>Characters Left: {{maxCharacters-(dataItem.oldData?
                                    dataItem.oldData.toString().length:0)}}
                                </kendo-label>
                            </div>
                            <kendo-textarea [rows]="5" resizable="both" [(ngModel)]="dataItem.oldData"
                                [maxlength]="maxCharacters" class="grid-input">
                            </kendo-textarea>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem *ngIf="item.changeTypeId === changeDataEnum.stockingType && this.isABU && this.allPlantSelected">
                        <kendo-dropdownlist [(ngModel)]="dataItem.oldData" class="grid-input" kendoGridFocusable
                                [defaultItem]="{ label: '-'}" [ngModelOptions]="{standalone: true}"
                                [valuePrimitive]="true" textField="label"
                                valueField="mnemonic" [data]="stockingTypeValues"></kendo-dropdownlist>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem *ngIf="item.changeTypeId !== changeDataEnum.other">
                        <div
                            *ngIf="(editStatus === dataItem.status) || (dataItem.status === undefined || dataItem.status === '' )">
                            {{dataItem.oldData}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="New Data">
                    <ng-template kendoGridCellTemplate let-dataItem
                        *ngIf="!isABU && (item.changeTypeId === changeDataEnum.other || item.changeTypeId === changeDataEnum.description)">
                        <div
                            *ngIf="(editStatus === dataItem.status) ||  (dataItem.status === undefined || dataItem.status === '' )">
                            <div class="text-right">
                                <kendo-label>Characters Left: {{maxCharacters-(dataItem.newData?
                                    dataItem.newData.toString().length:0)}}
                                </kendo-label>
                            </div>
                            <kendo-textarea class="{{getStyle(dataItem.hasError)}}" resizable="both" [rows]="5"
                                [(ngModel)]="dataItem.newData" [maxlength]="maxCharacters">
                            </kendo-textarea>
                        </div>
                        <div *ngIf="editStatus && dataItem.status && editStatus !== dataItem.status">
                            {{dataItem.newData}}
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-gridRowIndex="rowIndex"
                        *ngIf="!isABU && (item.changeTypeId === changeDataEnum.unitOfMeasure)">
                        <div
                            *ngIf="(editStatus === dataItem.status) ||  (dataItem.status === undefined || dataItem.status === '' )">

                            <kendo-dropdownlist [(ngModel)]="dataItem.newData" class="grid-input" kendoGridFocusable
                                [defaultItem]="{ displayName: '-'}" [ngModelOptions]="{standalone: true}"
                                (valueChange)="newDataChange($event,rowIndex,gridRowIndex,'uom')"
                                class="{{getStyle(dataItem.hasError)}}" textField="displayName" [valuePrimitive]="false"
                                valueField="id" [data]="uomData"></kendo-dropdownlist>
                        </div>
                        <div *ngIf="editStatus && dataItem.status && editStatus !== dataItem.status">
                            {{getNewValueName(dataItem.newData)}}
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-gridRowIndex="rowIndex"
                        *ngIf="!isABU && (item.changeTypeId === changeDataEnum.materialGroupCode)">
                        <div
                            *ngIf="(editStatus === dataItem.status) ||  (dataItem.status === undefined || dataItem.status === '' )">

                            <kendo-dropdownlist [(ngModel)]="dataItem.newData" class="grid-input" kendoGridFocusable
                                [defaultItem]="{ displayName: '-'}" [ngModelOptions]="{standalone: true}"
                                (valueChange)="newDataChange($event,rowIndex,gridRowIndex,'mgc')"
                                textField="displayName" [valuePrimitive]="false" valueField="id"
                                class="{{getStyle(dataItem.hasError)}}" [data]="materialGroupCodeData">
                            </kendo-dropdownlist>
                        </div>
                        <div *ngIf="editStatus && dataItem.status && editStatus !== dataItem.status">
                            {{getNewValueName(dataItem.newData)}}
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem
                        *ngIf="!isABU && (item.changeTypeId !== changeDataEnum.other
                    && item.changeTypeId !== changeDataEnum.unitOfMeasure && item.changeTypeId !== changeDataEnum.materialGroupCode)" let-gridRowIndex="rowIndex">
                        <div
                            *ngIf="(editStatus === dataItem.status) ||  (dataItem.status === undefined || dataItem.status === ''  || dataItem.status ===  null)">

                            <kendo-textbox *ngIf="item.changeTypeId !== changeDataEnum.leadTime"
                                class="{{getStyle(dataItem.hasError)}}" [(ngModel)]="dataItem.newData">
                            </kendo-textbox>
                            <kendo-numerictextbox [min]="0" (change)="onLeadTimeNewDataChange(rowIndex,gridRowIndex)"
                                *ngIf="item.changeTypeId === changeDataEnum.leadTime"
                                class="{{getStyle(dataItem.hasError)}}" [(ngModel)]="dataItem.newData"
                                [spinners]="false">
                            </kendo-numerictextbox>
                        </div>
                        <div *ngIf="editStatus && dataItem.status  && editStatus !== dataItem.status">
                            {{dataItem.newData}}
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem *ngIf="isABU && (item.changeTypeId !== changeDataEnum.plannerNumber) && (item.changeTypeId !== changeDataEnum.supplierNumber)">
                        <div>
                            <kendo-textbox class="{{getStyle(dataItem.hasError)}}" [(ngModel)]="dataItem.newData">
                            </kendo-textbox>
                        </div>
                        <div *ngIf="editStatus && dataItem.status  && editStatus !== dataItem.status">
                            {{dataItem.newData}}
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-gridRowIndex="rowIndex" *ngIf="isABU && (item.changeTypeId === changeDataEnum.plannerNumber)">
                        <div>
                            <kendo-dropdownlist [(ngModel)]="dataItem.newData" class="grid-input" kendoGridFocusable
                                [defaultItem]="{ name: '-'}" [ngModelOptions]="{standalone: true}"
                                (valueChange)="newDataChange($event,rowIndex,gridRowIndex,'plannerNumber')"
                                textField="name" [valuePrimitive]="false" valueField="id"
                                class="{{getStyle(dataItem.hasError)}}" [data]="plannerNumbersNewData">
                            </kendo-dropdownlist>
                        </div>
                        <div *ngIf="editStatus && dataItem.status && editStatus !== dataItem.status">
                            {{dataItem.newData}}
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-gridRowIndex="rowIndex" *ngIf="isABU && (item.changeTypeId === changeDataEnum.supplierNumber)">
                        <div>
                            <kendo-dropdownlist [(ngModel)]="dataItem.newData" class="grid-input" kendoGridFocusable
                                [defaultItem]="" [ngModelOptions]="{standalone: true}"
                                (valueChange)="newDataChange($event,rowIndex,gridRowIndex,'supplierNumber')"
                                [valuePrimitive]="true"
                                class="{{getStyle(dataItem.hasError)}}" [data]="suppliers">
                            </kendo-dropdownlist>
                        </div>
                        <div *ngIf="editStatus && dataItem.status && editStatus !== dataItem.status">
                            {{dataItem.newData}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column *ngIf="!newActionInputParam.isNew" title="Status" [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div [ngClass]="{'default-button status-btn':true,'draftbttn':dataItem.status === 'Draft', 
                        'rejectedbttn':dataItem.status === 'Rejected', 'completedbttn':dataItem.status === 'Completed',
                        'cancelledbttn':dataItem.status === 'Cancelled'}">{{dataItem.status}}</div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="" *ngIf="newActionInputParam.isNew" [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem let-column="column" let-index="rowIndex">
                        <div class="icons">
                            <div *ngIf="(item.changeTypeId === changeDataEnum.other ||
                             item.changeTypeId === changeDataEnum.leadTime) && 
                             getLengthOfMultiRow(item.changeTypeId,rowIndex,dataItem) "
                                (click)="addNew(item.changeTypeId)" class="k-icon k-i-plus">
                            </div>
                            <div *ngIf="(item.changeTypeId === changeDataEnum.other 
                            || item.changeTypeId === changeDataEnum.leadTime) && 
                            getLengthOfMultiRowDelete(item.changeTypeId,rowIndex)"
                                (click)="removeRow(item.changeTypeId,item.order!,rowIndex)" class="k-icon k-i-delete">
                            </div>

                        </div>
                    </ng-template>
                </kendo-grid-column>

            </kendo-grid>
        </div>
        <div class="mt-10">
            <kendo-label text="Requester Notes"></kendo-label> <br>
            <kendo-textarea class="mt-5" [(ngModel)]="requesterNotes" resizable="both" [rows]="4"></kendo-textarea>
        </div>
    </section>
</div>