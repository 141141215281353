<section>
    <div class="mt-20 text-align-right">
        <button kendoButton class="upload-button ml-20" *ngIf="isDraftavailable" (click)="editAndDownload()"> Download &
            Edit Items </button>
        <button kendoButton class="upload-button ml-20" (click)="import()"> Upload Excel </button>
        <button kendoButton class="upload-button ml-20" (click)="downloadTemplate()"> Download Excel Template</button>
        <button kendoButton class="upload-button ml-20" (click)="navigateToAddNewItem()"> Add New Item </button>
        <button kendoButton class="upload-button ml-20" [disabled]="selectedItems.length===0"
            (click)="bulkCancel()">Bulk
            Action </button>
        <button kendoButton class="upload-button ml-20" [disabled]="selectedItems.length===0"
            (click)="bulkCancel()">Bulk
            Action </button>
    </div>
</section>
<div *ngIf="!requiredParams.isNew">
    <section>
        <h3>Item List</h3>
        <!-- Grid -->
        <kendo-grid kendoGridSelectBy="requestLineId" [(selectedKeys)]="selectedItems" [selectable]="true"
            [data]="requestAddNewActionItemDetails!.items" [rowClass]="rowCallback" #item style="height:auto">
            <kendo-grid-checkbox-column [width]="50">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <input type="checkbox" id="checkbox-{{rowIndex}}"
                        *ngIf="!containsReject() && (dataItem.status ==='Rejected' || dataItem.status ==='Draft')"
                        [disabled]="accessForEditAction(dataItem) && containsReject() && (dataItem.status!=='Rejected' || dataItem.status!=='Draft')"
                        [kendoGridSelectionCheckbox]="rowIndex" />
                </ng-template>
                <ng-template kendoGridHeaderTemplate let-dataItem let-rowIndex="rowIndex">
                    <input kendoGridSelectAllCheckbox type="checkbox"
                        [disabled]="containsReject() && accessForEditAction(dataItem)" id="headerCheckbox" />
                </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column title="Line No." [width]="100">
                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
                    {{dataItem.lineNumber}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="description" title="Description">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div
                        *ngTemplateOutlet="(dataItem.status === 'Draft' || dataItem.status === 'Rejected') && accessForEditAction(dataItem) ? editDescription : textDescription; context: {dataItem: dataItem, rowIndex: rowIndex}">
                    </div>
                    <ng-template #editDescription let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <a href="javascript:void(0);" (click)="editLineItem(dataItem, rowIndex)">{{
                            dataItem.description }}</a>
                    </ng-template>
                    <ng-template #textDescription let-dataItem="dataItem" let-rowIndex>
                        {{ dataItem.description }}
                    </ng-template>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="manufactureName" title="Manufacturer"></kendo-grid-column>
            <kendo-grid-column field="manufacturePartNumber" title="Manufacturer Part Number"></kendo-grid-column>
            <kendo-grid-column field="unitOfMeasureName" title="Unit of Measure"></kendo-grid-column>
            <kendo-grid-column field="materialTypeName" title="Material Type"></kendo-grid-column>
            <kendo-grid-column *ngIf="!isABU" field="materialGroupCodeName" title="Material Group Code"></kendo-grid-column>
            <kendo-grid-column *ngIf="!isABU" field="segmentName" title="Segment"></kendo-grid-column>
            <kendo-grid-column *ngIf="isABU"field="addNewItemActionDetails.equipmentCriticality" title="Equipment criticality"></kendo-grid-column>
            <kendo-grid-column title="Status" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [ngClass]="{'default-button status-btn':true,'draftbttn':dataItem.status === 'Draft', 
                'rejectedbttn':dataItem.status === 'Rejected', 'completedbttn':dataItem.status === 'Completed',
                'cancelledbttn':dataItem.status === 'Cancelled'}"> {{dataItem.status}}</div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title=" " [width]="40">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <button kendoPopoverAnchor [popover]="myPopover" kendoButton iconClass="k-icon k-i-more-vertical"
                        class="k-popover-btn">
                    </button>
                    <kendo-popover #myPopover position="left">
                        <ng-template kendoPopoverBodyTemplate>
                            <div class="popover-content">
                                <a href="javascript:void(0);"
                                    *ngIf="(dataItem.status === 'Draft' || dataItem.status === 'Rejected') && accessForEditAction(dataItem)"
                                    (click)="editLineItem(dataItem, rowIndex)">Edit Line
                                    Item</a>
                                <a href="javascript:void(0);"
                                    *ngIf="(dataItem.status === 'Draft' || dataItem.status === 'Rejected') && accessForEditAction(dataItem)"
                                    (click)="rejectLineItem(dataItem,rowIndex)">Delete Line Item</a>
                                <a href="javascript:void(0);" *ngIf="dataItem.status === 'Rejected'"
                                    (click)="cancelLineItems(dataItem,rowIndex,'')">Cancel Line Item</a>
                                <a href="javascript:void(0);" (click)="cloneLineItem(dataItem,rowIndex)">Clone Line
                                    Item</a>
                            </div>
                        </ng-template>
                    </kendo-popover>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridDetailTemplate let-dataItem>
                <section>
                    <kendo-grid style="height:auto" scrollable="none" [data]="[dataItem.addNewItemActionDetails]">
                        <kendo-grid-column field="grossWeight" title="Gross Weight(LB)">
                        </kendo-grid-column>
                        <kendo-grid-column field="dimensions" title="Size/ Dimensions"></kendo-grid-column>
                        <kendo-grid-column field="materialStatusName" title="Plant Sp. Matl Status"></kendo-grid-column>
                        <kendo-grid-column field="storageConditioName" title="Storage Condition"></kendo-grid-column>
                        <kendo-grid-column field="unitPrice" title="Unit Price"></kendo-grid-column>
                        <kendo-grid-column field="leadTime" title="Lead Time (Days)"></kendo-grid-column>
                        <kendo-grid-column field="qaCodes" title="QA Code">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <ng-container *ngFor="let qaCode of dataItem.qaCodes">
                                    {{qaCode.qaCode}} <span *ngIf="( dataItem.qaCodes.length - 1 ) > rowIndex">,</span>
                                </ng-container>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="supplierPurchasing" title="Supplier (Purchasing)"></kendo-grid-column>
                        <kendo-grid-column field="supplierPartNumber" title="Supplier Part Number"></kendo-grid-column>
                    </kendo-grid>
                </section>                
                
                <section class="grid-container">
                    <kendo-grid class="grid-item-left" scrollable="none" [data]="[dataItem.addNewItemActionDetails]">
                        <kendo-grid-column title="Hazardous">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="dataItem.isHazardous">Yes</span>
                                <span *ngIf="!dataItem.isHazardous">No</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Critical">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="dataItem.isCritical">Yes</span>
                                <span *ngIf="!dataItem.isCritical">No</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="MRP" *ngIf="!isABU">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="dataItem.isMRp">Yes</span>
                                <span *ngIf="!dataItem.isMRp">No</span>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                    <kendo-grid class="grid-item-right" [data]="dataItem.addNewItemActionDetails.storageLocations">
                        <kendo-grid-column field="plantName" title="Plant"></kendo-grid-column>
                        <kendo-grid-column field="storageLocatinName" title="Storage Location"></kendo-grid-column>
                        <kendo-grid-column field="minimum" title="Minimum"></kendo-grid-column>
                        <kendo-grid-column field="maximum" title="Maximum"></kendo-grid-column>
                        <kendo-grid-column field="roundingValue" title="Rounding Value"></kendo-grid-column>
                        <kendo-grid-column field="estimatedInventoryValue" title="Estimated Inventory Value (USD)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                ${{dataItem.estimatedInventoryValue}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="specialProcurementKey" title="Special Procurement Key">
                        </kendo-grid-column>
                    </kendo-grid>
                </section>
            </ng-template>
        </kendo-grid>
    </section>

    <div class="separator  mb-20"></div>
    <div *ngIf="requestAddNewActionItemDetails!.items.length>0 && isDraftavailable">
        <div kendoTooltip tooltipClass="tooltip-design-caret">
            <fieldset title="Resolution details and attachment is only applicable for the items under “Draft” status"
                class="w-75 mb-20 fieldset">
                <legend>Attachments </legend>

                <kendo-label text="Action Attachments"></kendo-label><br>
                <div class="fileuploadContainer">
                    <p class="text">Drop Files or Click to Upload</p>
                    <input class="fileupload" type="file" #fileInput (change)="onFilesUpload($event)"
                        accept="accptedFilesStr" />
                </div>
                <kendo-grid *ngIf="attachmentDetailsDisplay && attachmentDetailsDisplay.length" #myGrid
                    [data]="attachmentDetailsDisplay" [hideHeader]="true" class="list-container" scrollable="none"
                    [style.width.px]="500">
                    <kendo-grid-column>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <button kendoButton (click)="downloadFile(rowIndex)" look="flat" [icon]="'download'"
                                class="download-button"></button>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="400">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{dataItem.fileName}}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <button kendoButton (click)="removeFile(rowIndex)" look="flat" [icon]="'delete'"
                                class="delete-button"></button>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </fieldset>
        </div>
        <fieldset class="w-75 fieldset">
            <legend>Assignment Details </legend>
            <div>
                <kendo-label text="Comment"></kendo-label><br>
                <kendo-textarea (change)="onChangeComment()" [(ngModel)]="comment"></kendo-textarea>
            </div>
            <!--  section 6 -->
            <section *ngIf="!isBoth" class="d-flex">
                <div class="mt-10 mx-5 w-50">
                    <kendo-label text="Assign To Group"><span style="color:red"> *</span></kendo-label><br>
                    <kendo-dropdownlist class="mt-5" [data]="assignToGroups" textField="name" valueField="id"
                        (selectionChange)="selectAssignToGroup($event)" [defaultItem]="defaultAssignToGroup"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>
                    <span *ngIf="!isGoupSelected" class="noValue"> Required field!
                        Please select group</span>
                    <br />
                </div>
                <div class="mt-10 mx-5 w-50">
                    <kendo-label text="Assign To User"></kendo-label><br>
                    <kendo-dropdownlist [(ngModel)]="selectedUser" [disabled]="!enabledAssignToUser" class="mt-5"
                        [data]="assignToUsers" [defaultItem]="defaultAssignToUser" textField="name" valueField="id"
                        (selectionChange)="selectAssignToUser($event)" [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </div>
            </section>
            <section *ngIf="isBoth">
                <div class="note">
                    <b> Note: <br /></b>
                    As there are actionable items on both Stocking approver and MMD,the items would be assigned to
                    respective groups
                    based on their Min/Max values.
                </div>
                <kendo-buttongroup selection="single">
                    <button class="c-button-gorup" kendoButton [toggleable]="true" (click)="tab('psa')"
                        title="Align-left" [selected]="true">Stocking
                        Approver</button>
                    <button class="c-button-gorup" kendoButton [toggleable]="true" (click)="tab('mmd')"
                        title="Align-center">MMD</button>
                </kendo-buttongroup>
                <fieldset class="mt-10">
                    <legend>Line Numbers</legend>
                    <ul class="line-numbers" *ngFor="let line of currentLineMumbers">
                        <li>Line {{line}},</li>
                    </ul>
                </fieldset>
            </section>
        </fieldset>
    </div>
</div>
