<section>
    <div class="mt-20 text-align-right">
        <button kendoButton class="upload-button ml-20" (click)="import()"> Upload Excel </button>
        <button kendoButton class="upload-button ml-20" (click)="navGenerateTemplate()"> Generate Excel </button>
        <button kendoButton class="upload-button ml-20" (click)="AddNewItem()"> Add New Item </button>
    </div>
</section>

<section>
    <h3>Item List</h3>

    <kendo-grid [data]="mainGridData">
        <kendo-grid-column [width]="30"></kendo-grid-column>
        <kendo-grid-column title="Line No." field="lineNumber" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="IDE Item Number" field="materialNumber" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Branch Plant Code" [width]="140">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem?.inventoryLevelUpdateGridData[0]?.code}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Unit Price" field="unitPrice" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Annual Usage Forecast" field="annualUsageForecast" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Stock Count" field="stockCount" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Total Value" field="totalValue" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Change in value" field="changeInValue" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Status" field="itemStatus" [width]="100"></kendo-grid-column>
        <kendo-grid-column title=" " [width]="50">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div kendoPopoverAnchor [popover]="myPopover" *ngIf="accessForEditAction(dataItem)">
                    <span class="k-icon k-i-more-vertical"></span>
                </div>
                <kendo-popover #myPopover position="bottom">
                    <ng-template kendoPopoverBodyTemplate>
                        <div class="popover-content">
                            <a href="javascript:void();" (click)="editLineItem(dataItem,rowIndex)">Edit Line
                                Item</a>
                            <hr class="separator ml-5 mr-5" />
                            <a href="javascript:void();" *ngIf="dataItem.status === 'Draft'"
                                (click)="rejectLineItem(dataItem,rowIndex)">Delete Line Item</a>
                            <a href="javascript:void();" *ngIf="dataItem.status === 'Rejected'"
                                (click)="cancelLineItems(dataItem,rowIndex,'')">Cancel Line Item</a>
                        </div>
                    </ng-template>
                </kendo-popover>
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridDetailTemplate let-dataItem>
            <section>
                <div class="d-i-flex j-c-s g-10">
                    <div>
                        <h4>Item Description</h4>
                        <kendo-textarea class="mt-5" [(ngModel)]="dataItem.itemDescription" [rows]="9" [cols]="50"
                            [readonly]="true" [resizable]="'none'"></kendo-textarea>
                    </div>
                    <div>
                        <h4>Recommended Stock Holding</h4>
                        <kendo-grid style="height:auto; width:700px" scrollable="none"
                            [data]="dataItem.inventoryLevelUpdateGridData">
                            <kendo-grid-column field="code" title="Branch Plant Code">
                            </kendo-grid-column>
                            <kendo-grid-column field="existingMinimum" title="Existing Minimum"></kendo-grid-column>
                            <kendo-grid-column field="recommendedMinimum"
                                title="Recommended Minimum"></kendo-grid-column>
                            <kendo-grid-column field="existingMaximum" title="Existing Maximum"></kendo-grid-column>
                            <kendo-grid-column field="recommendedMaximum"
                                title="Recommended Maximum"></kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </section>
        </ng-template>

    </kendo-grid>

</section>
<br>
<br>
<section *ngIf="showGridSection">
    <div kendoTooltip tooltipClass="tooltip-design-caret">
        <fieldset title="" class="mb-20 fieldset">
            <legend>Attachments </legend>
            <div class="d-w-b qj-c-s g-10">
                <div class="w-50">
                    <kendo-label text="Action Attachments"></kendo-label><br>
                    <div class="fileuploadContainer">
                        <p class="text">Drop Files or Click to Upload</p>
                        <input class="fileupload" type="file" #fileInput
                            (change)="onActionAttachmentsFilesUpload($event)" accept="accptedFilesStr" />
                    </div>
                    <kendo-grid *ngIf="actionAttachmentDetailsDisplay && actionAttachmentDetailsDisplay.length" #myGrid
                        [data]="actionAttachmentDetailsDisplay" [hideHeader]="true" class="list-container"
                        scrollable="none" [style.width.px]="500">
                        <kendo-grid-column>
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <button kendoButton (click)="downloadActionFile(rowIndex)" look="flat"
                                    [icon]="'download'" class="download-button"></button>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column [width]="400">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span>{{dataItem.fileName}}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column>
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <button kendoButton (click)="removeActionFile(rowIndex)" look="flat" [icon]="'delete'"
                                    class="delete-button"></button>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
                <div class="w-50">
                    <kendo-label text="Quote Attachments"></kendo-label><br>
                    <div class="fileuploadContainer">
                        <p class="text">Drop Files or Click to Upload</p>
                        <input class="fileupload" type="file" #fileInput
                            (change)="onActionAttachmentsFilesUpload($event)" accept="accptedFilesStr" />
                    </div>
                    <p>In case (Recommended Minimum = 0) and (Recommended Maximum > 0) <br>
                        and (Recommended Safety Stock
                        > 0) for any line items added, please ensure Quotes are uploaded</p>
                    <kendo-grid *ngIf="quoteAttachmentDetailsDisplay && quoteAttachmentDetailsDisplay.length" #myGrid
                        [data]="quoteAttachmentDetailsDisplay" [hideHeader]="true" class="list-container"
                        scrollable="none" [style.width.px]="500">
                        <kendo-grid-column>
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <button kendoButton (click)="downloadQuoteFile(rowIndex)" look="flat"
                                    [icon]="'download'" class="download-button"></button>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column [width]="400">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span>{{dataItem.fileName}}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column>
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <button kendoButton (click)="removeQuoteFile(rowIndex)" look="flat" [icon]="'delete'"
                                    class="delete-button"></button>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </fieldset>
    </div>

    <fieldset class="w-75 fieldset">
        <legend>Assignment Details </legend>
        <div>
            <kendo-label text="Comment"><span style="color:red"> *</span></kendo-label><br>
            <kendo-textarea (change)="onChangeComment()" [(ngModel)]="comment"></kendo-textarea>
        </div>
        <!--  section 6 -->
        <section *ngIf="!isBoth" class="d-flex">
            <div class="mt-10 mx-5 w-50">
                <kendo-label text="Assign To Group"><span style="color:red"> *</span></kendo-label><br>
                <kendo-dropdownlist class="mt-5" [data]="assignToGroups" textField="name" valueField="id"
                    (selectionChange)="selectAssignToGroup($event)" [defaultItem]="defaultAssignToGroup"
                    [valuePrimitive]="true">
                </kendo-dropdownlist>
                <span *ngIf="!isGroupSelected" class="noValue"> Required field!
                    Please select group</span>
                <br />
            </div>
            <div class="mt-10 mx-5 w-50">
                <kendo-label text="Assign To User"></kendo-label><br>
                <kendo-dropdownlist [(ngModel)]="selectedUser" [disabled]="!enabledAssignToUser" class="mt-5"
                    [data]="assignToUsers" [defaultItem]="defaultAssignToUser" textField="name" valueField="id"
                    (selectionChange)="selectAssignToUser($event)" [valuePrimitive]="true">
                </kendo-dropdownlist>
            </div>
        </section>
    </fieldset>
</section>