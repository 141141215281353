export const InventoryLevelUpdateConst = {
    excelvalidations: {
        noRows: 'No rows were found in the Excel spreadsheet',
        invalidColumnNames: 'Invalid column names',
        invalidMaterialNumber: 'Invalid Material Number',
        duplicateMaterialNumber: 'Material number is already exist. Please add required request line id in MMS Key column by this (1234-0) format to proceed',
        plantValidation: 'Invalid plant selected',
        storageLocationValidation: 'Invalid storage location selected',
        minimumValidation: 'Invalid Minimum value',
        maximumValidation: 'Invalid Maximum value',
        assetValidation: 'Invalid Asset',
        estimatedAnnualUsageValidation: 'Invalid Estimated Annual Usage',
        maximumValueGreaterValidation: 'Maximum value should be greater than minimum value',       
        unitCostValidation: 'Incorrect Unit Cost found',
        totalValueValidation: 'Incorrect Total Value found',
        successfulUploadMessage: 'Row added/updated successfully',       
    }
}