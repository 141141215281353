import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { requestConst } from 'src/app/core/constants/request.const';
import { CommonService } from 'src/app/core/services/common.service';
import { ApprovalDetailService } from 'src/app/features/sar/services/approvalDetail/approval-details.service';
import { GetItemList, GetTimelines } from '../../models/approval-details/get-approval-details.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AddNewEntryComponent } from '../add-new-entry/add-new-entry.component';
import { AddNewActionItemsAttachmentDetailsDisplay } from '../../models/common/action-attachment.model';
import { TempActionService } from '../../services/temp-action.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { RequestLineService } from '../../services/request-line.service';
import { RequestIssueDetails } from '../../models/sar-request/request-issue/request-issue-details.model';
import { BussinessUnitService } from 'src/app/features/setup/services/bussinessunit.service';
import { BusinessUnitDto } from 'src/app/features/setup/models/bussinessunits/bussinessunits.model';
import { RequestIssueService } from '../../services/request-issue.service';
import { RequestIssueTypeDto } from '../../models/sar-request/request-issue/request-issue-types.model';

@Component({
    selector: 'app-add-request',
    templateUrl: './add-request.component.html',
    styleUrls: ['./add-request.component.scss'],
    providers: [DatePipe]
})
export class AddRequestComponent implements OnInit, OnDestroy {
    requestId: number = 0;
    requestNumber: string = '';
    requestIssueId: number = 0;
    actionNumber: number = 0;
    action: string = '';
    timelineDetails: GetTimelines[] = [];
    itemsList: GetItemList[] = [];
    isSAActionActive: boolean = false;
    isMMDActionActive: boolean = false;
    listItems: Array<string> = [];
    filterItemList: Array<string> = [];
    currentStatus: string = '';
    modifiedDate: any;
    priorityName: string = '';
    justificationDetails: string = '';
    createdBy: string = '';
    modifiedBy: string = '';
    dateFormatWithTime: string = SharedConst.dateFormatWithTime;
    isOpen: boolean = false;
    @ViewChild("multiselect", { static: true }) public multiselect: any;
    isLineSelected: boolean = false;
    showSAActionForChangeData: boolean = false;
    currentTimelineData: any;
    createdByEmailId: string = '';
    buName?: string;
    businessUnitId?: number;
    requestIssueTypeId?: number;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private commonService: CommonService,
        private _approvalDetailService: ApprovalDetailService,
        private dialogService: DialogService,
        private tempActionService: TempActionService,
        public datepipe: DatePipe,
        private renderer: Renderer2,
        private el: ElementRef,
        private requestLineService: RequestLineService,
        private buService: BussinessUnitService,
        private requestIssueService: RequestIssueService,
    ) {
        const requestId = this.route.snapshot.params['requestId'];
        const trackingNumber = this.route.snapshot.params['trackingNumber'];
        const requestissueId = this.route.snapshot.params['requestissueId'];
        const issueNumber = this.route.snapshot.params['issueNumber'];
        const changeType = this.route.snapshot.params['changeType'].replace(/_/g, ' ');
        const stateData: any = this.router.getCurrentNavigation()?.extras.state;
        this.buName = this.commonService.getUserBUName();
        if (!this.commonService.isEmptyOrUndefined(stateData)) {
            this.requestId = stateData['requestId'];
            this.requestNumber = stateData['requestNumber'];
            this.requestIssueId = stateData['requestIssueId'];
            this.actionNumber = stateData['actionNumber'];
            this.action = stateData['changeType'];
            this.createdByEmailId = stateData['createdBY'];
            sessionStorage.setItem('addrequest', JSON.stringify(stateData));
        } else if (requestId && trackingNumber && requestissueId && issueNumber && changeType) {
            this.requestId = requestId;
            this.requestNumber = trackingNumber;
            this.requestIssueId = requestissueId;
            this.actionNumber = issueNumber;
            this.action = changeType;
        } else {
            let sessionState = JSON.parse(sessionStorage.getItem('addrequest')!);
            if (!this.commonService.isEmptyOrUndefined(sessionState)) {
                this.requestId = sessionState.requestId;
                this.requestNumber = sessionState.requestNumber;
                this.requestIssueId = sessionState.requestIssueId;
                this.actionNumber = sessionState.actionNumber;
                this.action = sessionState.changeType;
                this.createdByEmailId = stateData['createdBY'];
            }
        }
    }

    ngOnDestroy(): void {
        sessionStorage.removeItem('addrequest');
    }

    ngOnInit(): void {
        if (this.action !== 'Change Data') {
            this.showSAActionForChangeData = true;
        }
        this.requestSARActionsList();
        this.getTimelineDetails();
        this.getItemList();
        this.showHideButtonsByUserRole();
        this.getSARDetails();
        this.getBU();
        this.getRequestIssueType();
    }

    navViewSar() {
        const path = `false/false/${this.requestId}`
        this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
            state: { isManage: false, isCreate: false, requestId: this.requestId },
            relativeTo: this.route.parent,
        });
    }

    // to get details of timeline
    getTimelineDetails(): void {
        this._approvalDetailService
            .getTimelines(this.requestIssueId)
            .subscribe((data: any) => {
                if (data) {
                    data.map((ele: any) => {
                        ele.createdDate = new Date(ele.createdDate + 'Z');
                        this.timelineDetails = data;
                    })
                }
                this.currentTimelineData = JSON.parse(JSON.stringify(this.timelineDetails));
            });
    }

    // to get all the list of items
    getItemList(): void {
        this._approvalDetailService
            .getItemList(this.requestId, this.requestIssueId)
            .subscribe((data: GetItemList[]) => {
                this.itemsList = data;
                this.itemListByLineNumber(data);
            });
    }

    // to get SAR details based on ID
    getSARDetails(): void {
        this._approvalDetailService.getSARDetailsById(this.requestId).subscribe((data: any) => {
            this.modifiedDate = new Date(data.modifiedDateTime + 'Z');
            this.priorityName = data.priorityName;
            this.justificationDetails = data.justification
            this.createdBy = data.createdBy
            this.modifiedBy = data.modifiedBy
        })
    }

    getBU(): void {
        this.buService.getBUAll().subscribe(data => {
            let bu: BusinessUnitDto[] = data.filter(e => e.shortName === this.buName);
            if (bu.length > 0) {
                this.businessUnitId = bu[0].id;
            }
        });
    }

    getRequestIssueType(): void {
        const isChangeSar = this.action === "Change Data" || this.action === "Extension MRP Update";
        this.requestIssueService.getRequestIssueTypes(isChangeSar).subscribe(data => {
            const requestIssueType: RequestIssueTypeDto[] = data.filter(e => e.label?.toLocaleLowerCase() === this.action.toLocaleLowerCase()) as RequestIssueTypeDto[];
            if (requestIssueType.length > 0) {
                this.requestIssueTypeId = requestIssueType[0].id as number;
            }
        });
    }

    // to navigate to view-approval page with particular clicked button
    viewapproval(actionName: string): void {
        this.router.navigate(['../../../../../../' + 'viewapproval'], {
            state: {
                requestId: this.requestId,
                requestNumber: this.requestNumber,
                requestIssueId: this.requestIssueId,
                actionNumber: this.actionNumber,
                changeType: this.action,
                actionName: actionName,
            },
            relativeTo: this.route,
        });
    }

    // to show/hide action buttons based on User role
    showHideButtonsByUserRole(): void {
        const userRole = this.commonService.getUserRoleName();
        switch (userRole) {
            case RoleConstant.ESAR_Admin: {
                this.isSAActionActive = true;
                this.isMMDActionActive = true;
                break;
            }
            case RoleConstant.ESAR_MMD: {
                this.isSAActionActive = false;
                this.isMMDActionActive = true;
                break;
            }
            case RoleConstant.ESAR_Requestor: {
                this.isSAActionActive = false;
                this.isMMDActionActive = false;
                break;
            }
            case RoleConstant.ESAR_StockingApprover: {
                this.isSAActionActive = true;
                this.isMMDActionActive = false;
                break;
            }
            case RoleConstant.ESAR_ReadOnly: {
                this.isSAActionActive = false;
                this.isMMDActionActive = false;
                break;
            }
        }
    }

    // to open the dialog_box to add new_entry
    addNewEntry(): void {
        const dialog: DialogRef = this.dialogService.open({
            content: AddNewEntryComponent,
        });
        const inc = dialog.content.instance as AddNewEntryComponent;
        inc.data = {
            itemsList: this.itemsList,
            requestID: this.requestId,
            businessUnitId: this.businessUnitId!,
            requestIssueTypeId: this.requestIssueTypeId!,
            requestIssueID: this.requestIssueId,
            createdBy: this.createdBy,
            modifiedBy: this.modifiedBy
        }
        dialog.result.subscribe((res) => {
            if (res == 'success') {
                this.getTimelineDetails();
                this.getSARDetails();
                this.requestSARActionsList();
            }
        })
    }

    // to get all the line-numbers from items-list
    itemListByLineNumber(item: GetItemList[]): void {
        const line = item.map((ele: any) => {
            return ele.lineNumber;
        });
        this.listItems = line.filter((el, i, a) => i === a.indexOf(el));
        this.filterItemList = JSON.parse(JSON.stringify(this.listItems));
    }

    // to filter timeline according to selected item's line-number
    async selectItemList(items: any) {
        this.timelineDetails = [];
        this.isLineSelected = true;
        if (items.length == 0) {
            this.getTimelineDetails();
            this.isLineSelected = false;
        } else {
            for (let item of items) {
                this.currentTimelineData.filter((list: any) => {
                    if (list['lineItems'].includes(item)) {
                        this.timelineDetails.push(list);
                    }
                })
            }
            return this.timelineDetails = await this.removeDuplicates(this.timelineDetails, 'requestEntryId');
        }
    }

    removeDuplicates(myArray: any, Prop: any) {
        return myArray.filter((obj: any, pos: any, arr: any) => {
            return arr.map((mapObj: any) => mapObj[Prop]).indexOf(obj[Prop]) === pos;
        });
    }

    editSARDetails() {
        this.router.navigate(["./" + requestConst.manageSAR], {
            state: { isCreate: false, requestId: this.requestId }, relativeTo: this.route.parent
        });
    }

    // to download individual attached file
    downloadFile(timelineIndex: number, index: number) {
        if (this.timelineDetails[timelineIndex].attachments && this.timelineDetails[timelineIndex].attachments[index]) {
            let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.timelineDetails[timelineIndex].attachments[index];
            if (attachment.attachmentId !== 0) {
                this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
                    this.commonService.downloadFile(response, attachment.fileName!);
                });
            }
            else {
                const blob = new Blob([attachment.file!], { type: attachment.file!.type });
                this.commonService.downloadFile(blob, attachment.fileName!);
            }
        }
    }

    // to delete the attached file
    removeFile(timelineIndex: number, index: number) {
        if (this.timelineDetails[timelineIndex].attachments && this.timelineDetails[timelineIndex].attachments[index]) {
            let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.timelineDetails[timelineIndex].attachments[index];
            if (attachment.attachmentId !== 0) {
                this._approvalDetailService.deleteTimelineAttachement(this.timelineDetails[timelineIndex].requestEntryId!, attachment.attachmentId!).subscribe((data: any) => { })
            }
            this.timelineDetails[timelineIndex].attachments!.splice(index, 1);
        }
    }

    // to download all attached file
    downloadAll(i: number) {
        let date = new Date();
        const dateFormat = this.datepipe.transform(date, 'YYYY-MM-dd-HHmmss');
        let zipFileName = `${this.requestNumber}_${dateFormat}`;
        this._approvalDetailService.downloadAllAttachements(zipFileName, this.timelineDetails[i].attachments).subscribe((data: any) => {
            const blob = new Blob([data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            FileSaver.saveAs(url, zipFileName)
        })
    }

    searchLine(event: any) {
        this.isLineSelected = true;
        const filteredItems = this.filterItemList.filter((ele: any) => {
            return ele.toLowerCase().includes(event.target.value.toLowerCase());
        })
        this.listItems = filteredItems;
    }

    onOpen(event: any) {
        let multiselect;
        multiselect = this.el?.nativeElement?.querySelector("#multiSelect-dropdown");
        this.renderer?.addClass(multiselect, 'opened')
    }

    onClose(event: any) {
        let multiselect;
        multiselect = this.el?.nativeElement?.querySelector("#multiSelect-dropdown");
        this.isOpen = false;
        this.renderer?.removeClass(multiselect, 'opened');
    }

    toggle() {
        this.isOpen = !this.isOpen;
        this.multiselect.toggle(this.isOpen);
    }

    requestSARActionsList() {
        this.requestLineService.getActions(this.requestNumber).subscribe((actions: RequestIssueDetails[]) => {
            actions.some((list: any) => {
                if (list.actionId == this.actionNumber && list.requestissueId == this.requestIssueId) {
                    this.currentStatus = list?.status
                }
            })
        })
    }

    hideShowAddNewEntry() {
        if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
            return false;
        }
        return true;
    }

    accessForEdit() {
        if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
            return true;
        }
        else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
            return false;
        }
        else {
            if (this.commonService.getUserEmailId() === this.createdBy) {
                return true;
            }
            return false;
        }

    }

}