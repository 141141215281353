import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewActionInputParam } from '../../models/common/new-action-input-param.model';
import { InventoryLevelUpdateService } from '../../services/inventory-level-update.service';
import { InventoryLevelUpdateDto, InventoryLevelUpdateGridDataDto, InventoryLevelUpdateImportFromExcelDto } from '../../models/change-sar/inventory-level-update.model';
import { AddNewActionItemsAttachmentDetails, AddNewActionItemsAttachmentDetailsDisplay } from '../../models/common/action-attachment.model';
import { DeleteLineItemDto } from '../../models/common/delete-line-item.model';
import { FileConstant } from 'src/app/core/constants/commonConst/file.const';
import { TempActionRequestDto } from '../../models/temp-actions/temp-action-request.module';
import { LineAssignmentDto } from '../../models/common/line-assignment.model';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { CommonService } from 'src/app/core/services/common.service';
import { TempActionService } from '../../services/temp-action.service';
import { GroupService } from 'src/app/features/setup/services/group.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { RequestLineService } from '../../services/request-line.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { MMDActionDto } from '../../models/approval-details/mmd-approval-caction.mode';
import { ApprovalDetailService } from '../../services/approvalDetail/approval-details.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { InventoryLevelUpdateConst } from 'src/app/core/constants/inventory-level-update.const';
import { parseNumber } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-inventory-level-update',
  templateUrl: './inventory-level-update.component.html',
  styleUrls: ['./inventory-level-update.component.scss']
})
export class InventoryLevelUpdateComponent implements OnInit {
  @Input() public requiredParams: NewActionInputParam = {};
  isBoth: boolean = false;
  isDraftavailable: boolean = false;
  mainGridData: InventoryLevelUpdateDto[] = [];
  inventoryLevelUpdateItemsFromExcel: InventoryLevelUpdateImportFromExcelDto[] = [];
  @Output() isDraftavailableEvent = new EventEmitter<boolean>();
  attachmentDetails?: AddNewActionItemsAttachmentDetails[] = [];
  actionAttachmentDetailsDisplay: AddNewActionItemsAttachmentDetailsDisplay[] = [];
  quoteAttachmentDetailsDisplay: AddNewActionItemsAttachmentDetailsDisplay[] = [];
  @Output() cancelEvent = new EventEmitter<DeleteLineItemDto>();
  comment: string | undefined = '';
  businessUnitId: number = 0;
  requestIssueTypeId: number = 0;
  deleteLineItem: DeleteLineItemDto = new DeleteLineItemDto();
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  @ViewChild('fileInput') fileInput: any;
  tempActionRequest!: TempActionRequestDto;
  @Output() tempActionRequestEvent = new EventEmitter<TempActionRequestDto>();
  lineAssignment: LineAssignmentDto = new LineAssignmentDto();
  @Output() lineAssignmentEvent = new EventEmitter<LineAssignmentDto>();
  assignToGroups: Group[] = [];
  assignToUsers: UserDto[] = [];
  enabledAssignToUser: boolean = false;
  selectedUser = 0;
  isNew: boolean = true;
  inventoryLevelUpdateSarIssueTypeId: number = 6;
  changeSarIssueTypeId: number = 5;
  showGridSection = false;
  uploadExcelErrors: string = '';
  countExcelUploadErrors: number = 0;

  @Input() public isGroupSelected: boolean = true;;
  public defaultAssignToGroup: { name: string; id: number } = {
    id: 29,
    name: "ABU Stocking Approver"
  };
  public defaultAssignToUser: { name: string; id: number } = {
    id: 0,
    name: "-"
  };

  constructor(
    public router: Router,
    public requestLineService: RequestLineService,
    public approvalDetailService: ApprovalDetailService,
    private route: ActivatedRoute,
    private inventoryLevelUpdateService: InventoryLevelUpdateService,
    private sarUsersService: SarUsersService,
    private groupService: GroupService,
    private commonService: CommonService,
    private tempActionService: TempActionService,
    private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.getInventoryLevelUpdateData();
    this.setLineAssignment();
    this.setTempActionRequest();
  }

  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });
    dialog.result.subscribe((r: any) => {
      this.addExcelData(r);
    });
  }

  public addExcelData(input: any) {
    this.inventoryLevelUpdateItemsFromExcel = [];
    this.countExcelUploadErrors = 0;
    this.uploadExcelErrors = '';
    let arr = JSON.parse(input.value)['Item Inventory'] as Array<any>;

    if (arr.length === 0) {
      this.commonService.showNotificationMessage(InventoryLevelUpdateConst.excelvalidations.noRows, 'warning', 'center');
      return;
    }

    if (this.validateFileColumnNames(arr[0])) {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.invalidColumnNames + '<br/>';
      this.countExcelUploadErrors++;
      return;
    }

    for (let i = 1; i < arr.length; i++) {
      let item = arr[i];

      if (this.emptyRow(item)) {
        break;
      }

      let _inventoryLevelUpdate: InventoryLevelUpdateImportFromExcelDto = this.createDefaultInventoryLevelUpdateImportFromExcelObject();
      let validatedRow = this.validateRow(item, _inventoryLevelUpdate, this.mainGridData);

      if (validatedRow != undefined) {
        this.inventoryLevelUpdateItemsFromExcel.push(validatedRow);
      }
    }

    if (this.countExcelUploadErrors > 0) {
      let errormessage: string = '';
      if (this.countExcelUploadErrors > 8) errormessage = `There are ${this.countExcelUploadErrors} errors in the excel file and the first 8 are:<br/>`;
      else errormessage = (this.countExcelUploadErrors === 1 ? 'There is 1 error' : `There are ${this.countExcelUploadErrors} errors`) + ' in the Excel spreadsheet:<br/>';
      errormessage += this.uploadExcelErrors;
      this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
    }
    else {
      this.inventoryLevelUpdateService.importItemFromFile(this.inventoryLevelUpdateItemsFromExcel)
        .subscribe((response) => {
          this.requiredParams.isNew = false;
          this.requiredParams.isEdit = false;
          this.requiredParams.requestIssueId = response.requestIssueId;
          this.requiredParams.requestLineId = response.requestLineId;
          this.getInventoryLevelUpdateData();
          this.commonService.showNotificationMessage(InventoryLevelUpdateConst.excelvalidations.successfulUploadMessage, 'success', 'right');
        },
        (error) => {
          this.requiredParams.isNew = true;
          this.requiredParams.isEdit = true;
          this.commonService.showNotificationMessage('Failed to upload the file. Please try again.', 'error', 'right');
        });      
    }
  }

  emptyRow(row: any): boolean {
    return (row['__EMPTY'] === undefined || row['__EMPTY'] === '' || row['__EMPTY_6'] === undefined || row['__EMPTY_6'] == '');
  }

  validateRow(row: any, inventoryLevelUpdateImportFromExcelItem: InventoryLevelUpdateImportFromExcelDto, list: InventoryLevelUpdateDto[]): InventoryLevelUpdateImportFromExcelDto | undefined {   
    if (row['__EMPTY'] === undefined || row['__EMPTY'] === '' || !Number.isInteger(parseInt(row['__EMPTY'].trim()))) {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.invalidMaterialNumber + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if ((list.filter(x => x.materialNumber == parseInt(row['__EMPTY'].trim()))).length > 0) {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.duplicateMaterialNumber + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if (row['__EMPTY_1'] === undefined || row['__EMPTY_1'] === '' || typeof row['__EMPTY_1'] !== 'string') {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.plantValidation + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if (row['__EMPTY_2'] === undefined || row['__EMPTY_2'] === '' || !Number.isInteger(parseInt(row['__EMPTY_2']))) {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.minimumValidation + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if (row['__EMPTY_3'] === undefined || row['__EMPTY_3'] === '' || !Number.isInteger(parseInt(row['__EMPTY_3']))) {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.maximumValidation + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if (row['__EMPTY_4'] === undefined || row['__EMPTY_4'] === '' || !Number.isInteger(parseInt(row['__EMPTY_4']))) {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.estimatedAnnualUsageValidation + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if (row['__EMPTY_5'] === undefined || row['__EMPTY_5'] === '' || typeof row['__EMPTY_5'] !== 'string') {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.assetValidation + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if (row['__EMPTY_6'] === undefined || row['__EMPTY_6'] === '' || typeof parseInt(row['__EMPTY_6']) !== 'number') {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.unitCostValidation + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    if (row['__EMPTY_7'] === undefined || row['__EMPTY_7'] === '' || typeof (row['__EMPTY_7']) !== 'number') {
      this.uploadExcelErrors += InventoryLevelUpdateConst.excelvalidations.totalValueValidation + '<br/>';
      this.countExcelUploadErrors++;
      return undefined;
    }

    inventoryLevelUpdateImportFromExcelItem.materialNumber = parseInt(row['__EMPTY']);
    inventoryLevelUpdateImportFromExcelItem.asset = row['__EMPTY_5'];
    inventoryLevelUpdateImportFromExcelItem.unitPrice = parseNumber(row['__EMPTY_6']);
    inventoryLevelUpdateImportFromExcelItem.annualUsageForecast = parseInt(row['__EMPTY_4']);
    inventoryLevelUpdateImportFromExcelItem.totalValue = parseNumber(row['__EMPTY_7']);
    inventoryLevelUpdateImportFromExcelItem.requesterNotes = row['__EMPTY_8'];
    inventoryLevelUpdateImportFromExcelItem.branchPlant = row['__EMPTY_1'];
    inventoryLevelUpdateImportFromExcelItem.minimum = parseInt(row['__EMPTY_2']);
    inventoryLevelUpdateImportFromExcelItem.maximum = parseInt(row['__EMPTY_3']);
    inventoryLevelUpdateImportFromExcelItem.createdBy = this.commonService.getUserEmailId();

    return inventoryLevelUpdateImportFromExcelItem;
  }

  validateFileColumnNames(firstRow: any): boolean {
    const validInventoryLevelUpdateRow: {
      [key: string]: string;
    } = {
      '"MMS Item Inventory': 'MMS Key (System Use Only)',
      '__EMPTY': 'JDE Item Number',
      '__EMPTY_1': 'Branch Plant',
      '__EMPTY_2': 'Minimum',
      '__EMPTY_3': 'Maximum',
      '__EMPTY_4': 'Estimated Annual Usage',
      '__EMPTY_5': 'Asset For Unit Price',
      '__EMPTY_6': 'Unit Cost',
      '__EMPTY_7': 'Total Value (AUD)',
      '__EMPTY_8': 'Requestor Notes',
    };

    return Object.keys(firstRow).every(key => firstRow[key] === validInventoryLevelUpdateRow[key]);
  }

  updateUploadError(rowIndex: number, message: string) {
    this.countExcelUploadErrors++;

    if (this.countExcelUploadErrors <= 8) {
      this.uploadExcelErrors += `Row ${rowIndex + 4}: ${message}<br/>`
    }
  }

  private createDefaultInventoryLevelUpdateImportFromExcelObject(): InventoryLevelUpdateImportFromExcelDto {
    return {
      id: 0,
      requestId: this.requiredParams.requestId!,
      requestIssueId: this.requiredParams.requestIssueId,
      requestIssueTypeId: 6,
      lineNumber: 0,
      materialNumber: 0,
      branchPlant: '',
      asset: this.requiredParams.assetName!,
      unitPrice: 0,
      annualUsageForecast: 0,
      totalValue: 0,
      requesterNotes: '',
      minimum: 0,
      maximum: 0,
      createdBy: ''
    };
  }

  navGenerateTemplate() {
    this.router.navigate(["../" + 'inventory-level-update-generate-excel'], {
      state: {
        isNew: true,
        requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId,
        requestIssueTypeId: this.changeSarIssueTypeId,
        requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber,
        changeType: 'Inventory Level Update',
        assetName: this.requiredParams.assetName,
        lineItem: 0,
        requestLineId: 0
      },
      relativeTo: this.route
    });
  }

  getInventoryLevelUpdateData() {
    this.inventoryLevelUpdateService
      .getInventoryLevelUpdateData(this.requiredParams.requestId!)
      .subscribe((data) => {
        if ((data && data.length > 0 && !this.requiredParams.isNew && !this.requiredParams.isEdit) ||
          (data && data.length > 0 && data[0].totalValue == 0 && !this.requiredParams.isNew && !this.requiredParams.isEdit)
        ) {
          this.showGridSection = true;
        }
        else {
          this.showGridSection = false;
        }

        this.mainGridData = data;
        let draftRequestLineIds = this.mainGridData.filter(item => item.status === 'Draft').map(item => item.id);
        let deleteLineItem: DeleteLineItemDto = {
          requestIssueId: this.requiredParams.requestIssueId!,
          requestLineIds: draftRequestLineIds
        }
        this.cancelEvent.emit(deleteLineItem);  
        this.setAssignmentGroups();              
      });
  }

  setAssignmentGroups() {    
    const abuStockingApprover: number = 29;    
    const abuGorgonStockingApprover: number = 58;
    const abuWheatstoneStockingApprover: number = 59;
    const abuWaOilStockingApprover: number = 60;
    let draftItems = this.mainGridData
                         .filter(item => item.status === 'Draft' && item.inventoryLevelUpdateGridData.length > 0);
    
    if (this.requiredParams.assetName!.includes('Wheatstone') && draftItems.length > 0) {
      this.groupService.getGroupById(abuWheatstoneStockingApprover).subscribe((res: Group) => {
        this.assignToGroups.push(res);
        this.defaultAssignToGroup = { id: res.id, name: res.name! };
      });
    }

    else if (this.requiredParams.assetName!.includes('Gorgon') && draftItems.length > 0) {
      this.groupService.getGroupById(abuGorgonStockingApprover).subscribe((res: Group) => {
        this.assignToGroups.push(res);
        this.defaultAssignToGroup = { id: res.id, name: res.name! };
      });
    }

    else if (this.requiredParams.assetName!.includes('WA Oil') && draftItems.length > 0) {
      this.groupService.getGroupById(abuWaOilStockingApprover).subscribe((res: Group) => {
        this.assignToGroups.push(res);
        this.defaultAssignToGroup = { id: res.id, name: res.name! };
      });
    }
    else {
      this.groupService.getGroupById(abuStockingApprover).subscribe((res: Group) => {
        this.assignToGroups.push(res);
      });
    }
    
  }

  setTempActionRequest() {
    this.tempActionRequest = new TempActionRequestDto();
    this.tempActionRequest.requestId = this.requiredParams.requestId;
    this.tempActionRequest.requestIssueId = this.requiredParams.requestIssueId;
    this.tempActionRequest.requestIssueTypeId = this.requestIssueTypeId;
    this.tempActionRequest.assetName = this.requiredParams.assetName;
    this.tempActionRequest.attachments = [];
    this.tempActionRequest.comment = this.comment;
    this.tempActionRequest.createdBy = this.commonService.getUserEmailId();
    this.tempActionRequest.modifiedBy = this.commonService.getUserEmailId();
  }

  getAssignmentUsers() {
    if (this.lineAssignment.assignedGroupId) {
      this.sarUsersService.getAssignedUsers(this.lineAssignment.assignedGroupId).subscribe((data: any) => {
        this.assignToUsers = data;
      })
    }
  }

  selectAssignToGroup(group: Group) {
    this.lineAssignment.assignedGroupId = group.id;
    this.lineAssignment.assignedUserId = 0;
    this.selectedUser = 0;
    this.lineAssignment.isMixedValues = true;
    this.getAssignmentUsers();
    this.enabledAssignToUser = group.id ? true : false;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }

  selectAssignToUser(user: UserDto) {
    this.lineAssignment.assignedUserId = user.id;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }

  accessForEditAction(dataItem: InventoryLevelUpdateDto) {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === dataItem.createdBy) {
        return true;
      }
      return false;
    }
  }

  editLineItem(dataItem: InventoryLevelUpdateDto, index: number) {
    this.router.navigate(["../" + 'inventory-level-update-add-new-item'], {
      state: {
        isNew: false,
        id: dataItem.id,
        requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId,
        requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber,
        changeType: this.requiredParams.changeType,
        lineItem: dataItem.lineNumber,
        lineItemStatus: dataItem.status,
        requestLineId: this.requiredParams.requestLineId,
        materialNumber: dataItem.materialNumber,
        status: dataItem.status,
        unitPrice: dataItem.unitPrice,
        itemDescription: dataItem.itemDescription,
        annualUsageForecast: dataItem.annualUsageForecast,
        stockCount: dataItem.stockCount,
        totalValue: dataItem.totalValue,
        changeInValue: dataItem.changeInValue,
        requesterNotes: dataItem.requesterNotes,
        inventoryLevelUpdateGridData: dataItem.inventoryLevelUpdateGridData,
        assetName: this.requiredParams.assetName,
        isEditLineAction: true,
        buId: this.businessUnitId
      },
      relativeTo: this.route
    },
    );
  }

  rejectLineItem(dataItem: InventoryLevelUpdateDto, index: number) {
    this.commonService.showConfirmation('Delete', ApprovalDetailsConstants.deleteMessage)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          let delItem = new DeleteLineItemDto();
          delItem.requestIssueId = this.requiredParams.requestIssueId!;
          delItem.requestLineIds = [];
          delItem.requestLineIds.push(dataItem.id!);
          this.deleteLineItem = delItem;
          this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
            this.getInventoryLevelUpdateData();
            this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'info', 'right');
          })
        }
      });
  }

  cancelLineItems(dataItem: InventoryLevelUpdateDto, index: number, comment: string) {
    this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          let mmd: MMDActionDto[] = [];
          mmd.push(this.getmmdAction(dataItem, index, comment))
          this.mmdBulkAction(mmd);
        }
      });
  }

  getmmdAction(dataItem: InventoryLevelUpdateDto, index: number, comment: string) {
    let mmdAction: MMDActionDto = new MMDActionDto();
    mmdAction.comment = comment;
    mmdAction.createdBy = this.commonService.getUserEmailId();
    mmdAction.modifiedBy = this.commonService.getUserEmailId();
    mmdAction.requestIssueId = dataItem.requestIssueId
    mmdAction.requestIssueTypeId = dataItem.requestIssueTypeId
    mmdAction.requestLineId = dataItem.id;
    mmdAction.MMDAction = 3;
    mmdAction.requestId = dataItem.requestId;
    mmdAction.isEditLineItem = false
    return mmdAction;
  }

  mmdBulkAction(mmdAction: MMDActionDto[]) {
    this.approvalDetailService.bulkmmdCancelAction(mmdAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage(SharedConst.cancelLineItem, 'success', 'right');
      this.getInventoryLevelUpdateData();
    });
  }

  downloadActionFile(index: number) {
    if (this.actionAttachmentDetailsDisplay && this.actionAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.actionAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
          this.commonService.downloadFile(response, attachment.fileName!);
        });
      }
      else {
        const blob = new Blob([attachment.file!], { type: attachment.file!.type });
        this.commonService.downloadFile(blob, attachment.fileName!);
      }
    }
  }

  downloadQuoteFile(index: number) {
    if (this.quoteAttachmentDetailsDisplay && this.quoteAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.quoteAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
          this.commonService.downloadFile(response, attachment.fileName!);
        });
      }
      else {
        const blob = new Blob([attachment.file!], { type: attachment.file!.type });
        this.commonService.downloadFile(blob, attachment.fileName!);
      }
    }
  }

  removeActionFile(index: number) {
    if (this.actionAttachmentDetailsDisplay && this.actionAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.actionAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.deleteTempAction(this.requiredParams.requestIssueId!, attachment.attachmentId!).subscribe(Response => { });
      }
      else {
        let oldAttachments: AddNewActionItemsAttachmentDetailsDisplay[] = this.actionAttachmentDetailsDisplay.filter((x: AddNewActionItemsAttachmentDetailsDisplay) => (x.attachmentId !== 0));
        let attachmentsIndex: number = index - oldAttachments.length;
        this.tempActionRequest.attachments?.splice(attachmentsIndex, 1);
        this.lineAssignment.attachments?.splice(attachmentsIndex, 1);
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      this.actionAttachmentDetailsDisplay!.splice(index, 1);
    }
  }

  removeQuoteFile(index: number) {
    if (this.quoteAttachmentDetailsDisplay && this.quoteAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.quoteAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.deleteTempAction(this.requiredParams.requestIssueId!, attachment.attachmentId!).subscribe(Response => { });
      }
      else {
        let oldAttachments: AddNewActionItemsAttachmentDetailsDisplay[] = this.quoteAttachmentDetailsDisplay.filter((x: AddNewActionItemsAttachmentDetailsDisplay) => (x.attachmentId !== 0));
        let attachmentsIndex: number = index - oldAttachments.length;
        this.tempActionRequest.attachments?.splice(attachmentsIndex, 1);
        this.lineAssignment.attachments?.splice(attachmentsIndex, 1);
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      this.quoteAttachmentDetailsDisplay!.splice(index, 1);
    }
  }

  onChangeComment() {
    this.lineAssignment.comment = this.comment;
    this.lineAssignment.isGeneralComment = false;
    this.lineAssignment.assignedGroupId = this.defaultAssignToGroup.id;
    this.lineAssignment.isMixedValues = true;
    this.lineAssignment.requestIssueId = this.requiredParams.requestIssueId;
    this.lineAssignment.recentRequestLineId = this.requiredParams.requestLineId!;
    this.lineAssignment.requestLineIds = this.requiredParams.requestLineId ? [this.requiredParams.requestLineId] : [];
    this.tempActionRequest.comment = this.comment;
    this.tempActionRequestEvent.emit(this.tempActionRequest);
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }

  onActionAttachmentsFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = this.setNewAttachment(files[0]);
        this.tempActionRequest.attachments!.push(files[0]);
        this.lineAssignment.attachments?.push(files[0]);
        this.actionAttachmentDetailsDisplay.push(newAttachment);
        this.fileInput.nativeElement.value = '';
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  onQuoteAttachmentsFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = this.setNewAttachment(files[0]);
        this.tempActionRequest.attachments!.push(files[0]);
        this.lineAssignment.attachments?.push(files[0]);
        this.quoteAttachmentDetailsDisplay.push(newAttachment);
        this.fileInput.nativeElement.value = '';
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }

  setNewAttachment(file: File): AddNewActionItemsAttachmentDetailsDisplay {
    const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = new AddNewActionItemsAttachmentDetailsDisplay();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  setLineAssignment() {
    this.lineAssignment.requestId = this.requiredParams.requestId;
    this.lineAssignment.requestIssueId = this.requiredParams.requestIssueId;
    this.lineAssignment.requestIssueTypeId = this.inventoryLevelUpdateSarIssueTypeId;
    this.lineAssignment.recentRequestLineId = this.requiredParams.requestLineId!;
    this.lineAssignment.businessUnitId = this.businessUnitId;
    this.lineAssignment.assignedGroupId = 0;
    this.lineAssignment.assignedUserId = 0;
    this.lineAssignment.state = 0;
    this.lineAssignment.isGeneralComment = false;
    this.lineAssignment.comment = this.comment;
    this.lineAssignment.createdBy = this.commonService.getUserEmailId();
    this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
    this.lineAssignment.attachments = [];
  }

  AddNewItem() {
    let isDataExit = false;
    this.router.navigate(["../" + 'inventory-level-update-add-new-item'], {
      state: {
        isNew: true,
        requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId,
        requestIssueTypeId: this.changeSarIssueTypeId,
        requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber,
        changeType: 'Inventory Level Update',
        assetName: this.requiredParams.assetName,
        lineItem: 0,
        requestLineId: 0,
        isDataExist: isDataExit
      },
      relativeTo: this.route
    },
    );
  }
}
